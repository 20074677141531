<template>
    <div class="d-flex justify-content-center align-items-center min-vh-100">
        <div class="card text-center shadow-lg" style="width: 24rem;">
            <!-- <div class="card-header bg-primary text-white">
                <h4>{{ appName }}</h4>
            </div> -->
            <div class="card-body">
                <template v-if="status === 'success'">
                    <div class="icon success-icon mb-3">
                        <i class="bi bi-check-circle-fill text-success"></i>
                    </div>
                    <h5 class="card-title">{{ appName }} connected successfully</h5>
                    <p class="card-text">Your application is now successfully connected to {{ appName }}.</p>
                </template>
                <template v-else>
                    <div class="icon error-icon mb-3">
                        <i class="bi bi-x-circle-fill text-danger"></i>
                    </div>
                    <h5 class="card-title">Connection Failed</h5>
                    <p class="card-text">There was an issue connecting to {{ appName }}. Please try again later.</p>
                </template>
            </div>
            <div class="">
                {{ callbackData }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Integration-Callback",
    data() {
        return {
            callbackData: this.$route.query?.data || '',
            appName: this.capitalizeFirstLetter(this.getAppNameFromUrl()),
            status: this.$route.query?.status || ''
        };
    },
    methods: {
        getAppNameFromUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('name') || '';
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
};
</script>

<style scoped>
.min-vh-100 {
    min-height: calc(100vh - 88px) !important;
}

.card {
    border-radius: 10px;
}

.card-header {
    font-weight: bold;
    font-size: 1.2rem;
}

.card-body {
    padding: 2rem;
}

.icon.success-icon i {
    font-size: 3rem;
}

.icon.error-icon i {
    font-size: 3rem;
}

.card-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
    font-size: 0.9rem;
}
</style>
