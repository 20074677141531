<template>
    Terms
  </template>
  
  <script>
  export default {
      name: 'TermsVue',
  }
  </script>
  
  <style>
  
  </style>
  