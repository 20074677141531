<template>
    <div :class="['sidebar', { 'sidebar-visible': isSidebarVisible }]">
        <div class="offcanvas-body">
            <button class="navigate-btn mt-2"
                style="border: none; font-size: larger; font-size: 1.75rem; background: none;"
                @click=" backToDasboard()"><i class="bi bi-arrow-left"></i></button>
            <div class="accordion" id="nodeAccordion">
                <campaign-node v-for="(node, index) in filterNodes('Campaign')" :key="node.name" :node="node"
                    :campaigns="campaigns" :is-open="isAccordionVisible(index, 'campaign')"
                    @toggle-accordion="toggleAccordion(index, 'campaign')" @drag-start="dragStartNode"
                    @add-node="addNodeToEditor" @refresh-campaigns="fetchCampaigns" :editable-node="editableNode"
                    @update-campaign="updateCampaignNodeData" />
                <template-node v-for="(node, index) in filterNodes('Template')" :key="node.name" :node="node"
                    :templates="templates" :is-open="isAccordionVisible(index, 'template')"
                    @toggle-accordion="toggleAccordion(index, 'template')" @drag-start="dragStartNode"
                    @add-node="addNodeToEditor" @refresh-template="fetchTemplates" />
                <leads-node v-for="(node, index) in filterNodes('Leads')" :key="node.name" :node="node" :leads="leads"
                    :is-open="isAccordionVisible(index, 'leads')" @toggle-accordion="toggleAccordion(index, 'leads')"
                    @drag-start="dragStartNode" @add-node="addNodeToEditor" @refresh-leads="fetchLeads" />
                <execute-button-node v-for="(node, index) in filterNodes('Select Identity')" :key="node.name"
                    :node="node" :identities="identities" :is-open="isAccordionVisible(index, 'execute')"
                    @toggle-accordion="toggleAccordion(index, 'execute')" @drag-start="dragStartNode"
                    @add-identity="handleAddIdentity" @add-node="addNodeToEditor"
                    @refresh-identities="fetchIdentities" />
            </div>
        </div>
    </div>
    <div class="tgl-btn">
        <button class="toggle btn btn-primary position-absolute" :style="toggleButtonStyle" @click="toggleSidebar">
            <i :class="isSidebarVisible ? 'bi bi-chevron-left' : 'bi bi-chevron-right'" class="text-white"
                style="font-size: 18px;"></i>
        </button>
    </div>
</template>

<script>
import TemplateNode from "./TemplateNode.vue";
import LeadsNode from "./LeadsNode.vue";
import CampaignNode from "./CampaignNode/CampaignNode.vue";
import ExecuteButtonNode from "./ExecuteButtonNode.vue";

export default {
    name: "FlowSideBar",
    components: {
        TemplateNode,
        LeadsNode,
        CampaignNode,
        ExecuteButtonNode
    },
    props: {
        templates: Array,
        leads: Array,
        identities: Array,
        campaigns: Array,
        editableNode: Object,
        nodesData: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            currentNodeIndex: null,
            accordionType: "",
            isSidebarVisible: true,
        };
    },
    computed: {
        toggleButtonStyle() {
            return {
                zIndex: 1000,
                bottom: '50px',
                height: '30px',
                width: '30px',
                padding: '0px !important',
                borderRadius: '16px',
                left: this.isSidebarVisible ? '285px' : '0px',
                transform: 'translateY(-50%)',
                transition: 'left 0.0s ease-in-out',
            };
        }
    },
    methods: {
        addNodeToEditor(nodeData) {
            this.$emit('addNodeToEditor', nodeData);
        },
        toggleSidebar() {
            this.isSidebarVisible = !this.isSidebarVisible;
        },
        filterNodes(type) {
            return this.nodesData.filter(node => node.name === type);
        },
        isAccordionVisible(index, type) {
            return this.currentNodeIndex === index && this.accordionType === type;
        },
        toggleAccordion(index, type) {
            if (this.currentNodeIndex === index && this.accordionType === type) {
                this.currentNodeIndex = null;
                this.accordionType = "";
            } else {
                this.currentNodeIndex = index;
                this.accordionType = type;
            }
        },
        dragStartNode(event, nodeData) {
            this.$emit('dragStartNode', event, nodeData);
        },
        fetchTemplates() {
            this.$emit('fetchTemplates');
        },
        fetchLeads() {
            this.$emit('fetchLeads');
        },
        fetchIdentities() {
            this.$emit('fetchIdentities');
        },
        fetchCampaigns() {
            this.$emit('fetchCampaigns');
        },
        updateCampaignNodeData(updatedNodeData) {
            this.$emit('updateCampaignNodeData', updatedNodeData);
        },
        handleAddIdentity(newIdentity) {
            this.$emit('handleAddIdentity', newIdentity);
        },
        backToDasboard() {
            this.$router.push('/');
        }
    }
};
</script>

<style>
.sidebar {
    position: absolute;
    top: 0;
    left: 0px;
    width: 0px;
    background-color: white;
    transition: left 0.5s ease-in-out !important;
    z-index: 1000;
}

.sidebar-visible {
    top: 59px !important;
    left: 0px !important;
    width: 300px !important;
    height: calc(100vh - 89px) !important;
}

.offcanvas-body {
    margin: 0 10px !important;
    position: relative;
    margin: 0px;

}

.accordion-collapse {
    max-height: calc(100vh - 400px) !important;
    overflow-y: auto !important;
}

.accordion-button {
    height: 45px;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    justify-content: space-between;
    font-size: 14px !important;

}

.accordion {
    --bs-accordion-body-padding-x: 0rem !important;
    --bs-accordion-body-padding-y: 0rem !important;
}

.accordion-button:focus {
    z-index: 0 !important;
    border-color: none !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.list-group {
    margin: 10px !important;
}

.add-btn {
    height: 19px !important;
    width: 19px !important;
    display: flex;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ababb0 !important;
    border: none;
    color: white !important;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.list-group-item:hover .add-btn {
    background-color: #5A4FDC !important;
    /* transform: scale(1.1); */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.list-group-item {
    cursor: move;
    font-size: 14px !important;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 40px;
}

.refresh-btn {
    position: absolute;
    right: 50px;
    z-index: 999;
    font-size: 20px;
    border: none;
    background: transparent;
    top: 12px;
    color: green;
}
</style>
