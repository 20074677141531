<template>
    <div class="container-fluid main-content-container py-4">
        <div class="bg-white">
            <div class="bg-white">
                <div class="d-flex justify-content-between align-items-center p-3">
                    <h3 class="rovlin-prod-page-title">Transaction History</h3>
                    <div class="d-flex align-items-center">
                        <input type="date" class="form-control w-auto" v-model="startDate" :max="today"
                            style="margin-right: 10px;" />
                        <input type="date" class="form-control w-auto" v-model="endDate" :max="today" />
                        <button type="button" v-if="isFilterApplied" class="btn btn-cancel" @click="clearFilter"><i
                                class="bi bi-x" style="font-size: 17px;"></i></button>
                    </div>
                </div>

                <VueGoodTable class="rounded" :rows="filteredData" mode="remote" v-on:page-change="onPageChange"
                    v-on:per-page-change="onPerPageChange" :totalRows="totalRecords" :columns="columns"
                    :pagination-options="{
                        enabled: true,
                        perPageDropdownEnabled: false,
                        setCurrentPage: currentPage,
                    }" :sort-options="{ enabled: false }">
                    <template #table-row="{ row, column }">
                        <span v-if="column.field == 'payment_date'">{{ formatDateAndTime(row.payment_date) }}</span>
                        <span v-if="column.field == 'status'">
                            <a class="rovlin-prod text-success">
                                {{ row.status }}
                            </a>
                        </span>
                        <span v-if="column.field == 'amount'">
                            <a class="rovlin-prod">${{ row.amount }}</a>
                            <Popover v-if="row.c_status === 'TERMINATED'" icon="bi-info-circle" :content="row.c_status"
                                title="Campaign Status" />
                        </span>
                        <span v-if="column.field == 'transaction_id'">
                            <a class="rovlin-prod text-primary" @click="downloadInvoice(row)" style="cursor: pointer;">
                                {{ row.transaction_id }}
                            </a>
                        </span>
                        <!-- <span v-if="column.field == 'action'">
                            <div class="dropdown">
                                <button class="btn" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                    aria-expanded="false" style="border: none;">
                                    <i class="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li>
                                        <a class="dropdown-item" @click="downloadInvoice(row)">Download Invoice</a>
                                    </li>
                                </ul>
                            </div>
                        </span> -->
                    </template>
                </VueGoodTable>
            </div>
        </div>
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";
import { VueGoodTable } from "vue-good-table-next";
import Popover from "@/components/shared/Popper/Popover.vue"

export default {
    name: "TransactionComponent",
    components: {
        VueGoodTable,
        Popover
    },
    data() {
        return {
            columns: [
                { label: "S No", field: "SNo" },
                { label: "Transaction Id", field: "transaction_id" },
                { label: "Campaign Name", field: "Campaign_name" },
                { label: "Date", field: "payment_date" },
                { label: "Credit Added", field: "credits" },
                { label: "Amount", field: "amount" },
                { label: "Status", field: "status" },
                { label: "Type", field: "payment_type" },
                // { label: "Action", field: "action" },
            ],

            tableData: [],
            filteredData: [],
            startDate: new Date(),
            endDate: new Date(),
            currentPage: 1,
            pageSize: 10,
            totalRecords: 0,
            isFilterApplied: false,
            today: new Date().toISOString().split('T')[0],


        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalRecords / this.pageSize);
        },
        paginatedData() {
            const startIndex = (this.currentPage - 1) * this.pageSize;
            const endIndex = startIndex + this.pageSize;
            return this.tableData.slice(startIndex, endIndex);
        },
    },
    methods: {
        getAllReceipts() {
            console.log("this.currentPage =>", this.currentPage)
            this.$store.state.isLoading = true;
            APIService.getReceipts(this.currentPage).then((res) => {
                this.$store.state.transactionHistory = APIService.objectItemWithIndex(res.data.receipt_details, this.currentPage - 1, 10);
                this.totalRecords = res.data.receipt_count;
                console.log(" this.$store.state.transactionHistory", this.$store.state.transactionHistory)
                this.tableData = Array.from(this.$store.state.transactionHistory);
                this.filteredData = this.tableData;
            })


                .catch((error) => {
                    console.log("error", error);
                    this.$store.state.toastMessage = "Something Went Wrong";
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        filterByDate() {
            if (new Date(this.startDate) > new Date(this.endDate)) {
                this.$store.state.toastMessage = "Start date cannot be after the end date";
                return;
            }

            this.filteredData = this.tableData.filter((transaction) => {
                const paymentDate = new Date(transaction.payment_date);
                return paymentDate >= new Date(this.startDate) && paymentDate <= new Date(this.endDate);
            });

            if (this.filteredData.length === 0) {
                this.$store.state.toastMessage = "No transactions found in the selected date range";
            }
            this.isFilterApplied = true;
        },
        clearFilter() {
            this.filteredData = this.tableData;
            this.startDate = null;
            this.endDate = null;
            this.isFilterApplied = false;
            this.getAllReceipts();
        },
        downloadInvoice(row) {
            const transactionId = row.transaction_id;
            if (transactionId) {
                APIService.downloadInvoice(transactionId)
                    .then((response) => {
                        const link = document.createElement('a');
                        link.href = response.data.invoice_pdf;
                        link.setAttribute('download', `invoice_${transactionId}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();

                        setTimeout(() => {
                            this.$store.state.toastMessage = 'Invoice downloaded successfully!';
                            this.$store.state.showToast = true;

                            setTimeout(() => {
                                this.$store.state.toastMessage = '';
                                this.$store.state.showToast = false;
                            }, 3000);
                        }, 3000);
                    })
                    .catch(() => {
                        this.$store.state.toastMessage = 'Something went wrong!';
                        this.$store.state.showToast = true;

                        setTimeout(() => {
                            this.$store.state.toastMessage = '';
                            this.$store.state.showToast = false;
                        }, 3000);
                    });
            }
        },
        formatDateAndTime(dateTimeString) {
            const [datePart, timePart] = dateTimeString.split(" ");
            const [year, month, day] = datePart.split("-");
            const [hour, minute, second] = timePart.split(":");
            const date = new Date(year, month - 1, day, hour, minute, second);
            const formattedDate = new Intl.DateTimeFormat("en", {
                day: "numeric",
                month: "short",
                year: "numeric",
            }).format(date);
            const formattedTime = new Intl.DateTimeFormat("en", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            }).format(date);
            return `${formattedDate} - ${formattedTime}`;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.getAllReceipts();
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.getAllReceipts();
            }
        },
        onPageChange(pageObject, id) {
            this.currentPage = pageObject.currentPage;
            this.getAllReceipts();
            this.$router.push({
                name: "transaction-history",
                params: { group: this.groupId, groupName: this.groupName, id: id }, query: { page: pageObject.currentPage }
            });

        },
        onPerPageChange(perPage) {
            this.pageSize = perPage;
            this.currentPage = 1;
            this.getAllReceipts();
        },
    },
    watch: {
        endDate(newEndDate) {
            if (newEndDate && this.startDate) {
                this.isFilterApplied = true;
                this.filterByDate();
            }
        },
    },
    created() {
        this.getAllReceipts();
    },
};
</script>

<style scoped>
.rovlin-prod-page-title {
    font-weight: 500;
    color: #606266;
    font-size: 20px !important;
    /* margin-bottom: 20px; */
}

.rovlin-prod {
    color: #606266;
}

.form-control {
    height: 40px;
    /* margin-right: 10px; */
    border-radius: 6px;
    border: 1px solid #ddd;
}

.vue-good-table-wrapper {
    margin-top: 20px;
}

:deep(.vgt-table th) {
    text-align: center;
    background-color: #f5f6fa;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
}

:deep(.vgt-table td) {
    text-align: center;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}

/* :deep(.vgt-table tr:hover) {
    background-color: #f9f9f9;
} */

.btn-cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.btn-cancel:hover {
    color: #ff6666;
}

.dropdown .btn {
    padding: 0;
    font-size: 16px;
    color: #606266;
}

.dropdown-menu {
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    color: #606266;
}

.dropdown-item:hover {
    background-color: #f5f6fa;
    color: #000;
}

::v-deep .vgt-responsive {
    padding: 0rem 1rem !important;
}

::v-deep table.vgt-table {
    border: none;
}

::v-deep .vgt-table.bordered th {
    border: none;
}

::v-deep .vgt-table th {
    border-bottom: 1px solid #ddd !important;
    border-top: 1px solid #ddd !important;
}

::v-deep .vgt-table.bordered td {
    border: none;
}

::v-deep .vgt-table td {
    border-bottom: 1px solid #ddd !important;
    padding: 0.5em 1em 0.5em 0.5em !important;
}

::v-deep .vgt-wrap__footer {
    border: none !important;
    background: #fff !important;

}

::v-deep .vgt-table thead th {
    background: #fff !important;
}

/* ::v-deep .footer__navigation__page-btn{
    float: left !important;

} */
</style>
