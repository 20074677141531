import utilsService from "../../../../../Services/utils/utils.service";
export default function getNlTemplate(nlStyle,apiKey,leadsGroup) {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>

        <style>
            * {
                font-family: Arial, Helvetica, sans-serif;
            }

            .nl-header {
                font-size: ${nlStyle.heading.size}px;
                font-size: 24px !important;
                font-weight: 500;
                color: ${nlStyle.heading.color};
                margin: 0;
            }

            .nl-main {
                padding:20px;
                border-radius:7px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background:${nlStyle.background}
            }

            .nl-sub-header {
                color: ${nlStyle.subHeading.color};
                font-size: ${nlStyle.subHeading.size}px;
                font-size: 14px !important;
            }

            .nl-form-control {
                width: ${nlStyle.input.width}px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
                border: 1px solid  #e9edf6;
                color: #333335 !important;
                background-color: #ffffff !important;
                font-size: 0.875rem;
                font-weight: 400 !important;
                line-height: 1.6;
                border-radius: 0.35rem;
                padding: 0.5rem 0.85rem;
            }

            .nl-form-control:hover:active {
                outline: 0 !important;
            }

            .nl-submit-button {
                background: ${nlStyle.button.color}!important;
                border: none;
                color: white;
                padding: 8px;
                font-size:13px;
                border-radius: 10px;
                cursor: pointer;
                height:37px
            }
            .nl-is-invalid {
                border-color: #dc3545 !important;
            }

            .nl-invalid-feedback {
                color: #dc3545;
                font-size: 13px;
                display: none;
                margin-top: 4px
            }
        </style>
    </head>
    <body>
        <div class="nl-main">
            <p class="nl-header">${nlStyle.heading.text}</p>
            <p class="nl-sub-header">${nlStyle.subHeading.text}</p>
            <div style="margin-top: 10px;margin-bottom: 4px;display: flex">
            <div>  <input type="text" class="nl-form-control" id="emailInput" placeholder="${nlStyle.input.text}" />
            <div class="nl-invalid-feedback" id="invalidMsg">Invalid email address</div></div>
                <button class="nl-submit-button" style="margin-left: 14px;" onclick="submitEmail()">${nlStyle.button.text}</button>
            </div>
        </div>

        <script>
             function submitEmail() {
                const emailInput = document.getElementById('emailInput');
                const feedbackElement = document.getElementById('invalidMsg'); // Invalid feedback div

                const email = emailInput.value;

                if (!email || !validateEmail(email)) {
                    emailInput.classList.add('nl-is-invalid');
                    feedbackElement.style.display = 'block';
                    return;
                }
                emailInput.classList.remove('nl-is-invalid');
                feedbackElement.style.display = 'none';

                fetch('${process.env.VUE_APP_ROOT_API}collect-leads/${leadsGroup || ''}', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'apiKey':'${apiKey}'
                    },
                    body: JSON.stringify({ email: email }),
                })
                .then(response => response.json())
                .then(data => {
                    console.log('Data:', data);
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert('Failed to submit the email.');
                });
            }

            function validateEmail(email) {
                const re =  ${utilsService.EMAIL_REGEX};
                return re.test(String(email).toLowerCase());
            }
        </script>
    </body>
    </html>
`;
}

export const nlConfig = {
    background: "#ffffff",
    heading: {
        text: "Subscribe",
        color: "#003366",
        size: "37",
    },
    subHeading: {
        text: "Subscribe to our news letter and stay updated.",
        color: "#7f7f7f",
        size: 17,
    },
    input: {
        text: "Your Email",
        width: "180",
    },
    button: {
        text: "Submit",
        color: "#4CAF50",
    },
};
