<template>
    <div class="container-fluid main-content-container px-4 pb-4">
        <highCharts :options="lineChart"></highCharts>
        <div class="row mt-2">
            <div class="col">
                <highCharts :constructor-type="'chart'" :options="pieChart" ref="chart"></highCharts>
            </div>
            <div class="col text-secondary">
                <h4 class="text-secondary font-weight-bold">
                    {{this.campaignStatsDetails.get_campaign_stats_rates.opened_rate.toFixed()}}%
                </h4>
                <p>of all recipients opened so far</p>
                <h4 class="text-secondary font-weight-bold">
                    {{this.campaignStatsDetails.get_campaign_stats_rates.unsubscribed_rate}}
                </h4>
                <p>clicked a link</p>
                <h4 class="text-secondary font-weight-bold">
                    {{this.campaignStatsDetails.get_campaign_stats_rates.unsubscribed_rate}}%
                </h4>
                <p>unsubscribed</p>
                <h4 class="text-secondary font-weight-bold">
                    {{this.campaignStatsDetails.get_campaign_stats_rates.spam_rate}}
                </h4>
                <p>people marked it as spam</p>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
    name: "CampaignGraph",
    props: ["campaignStatsDetails"],
    components: { highCharts: Chart },

    data() {
        return {
            uniqueOpens: [],
            pieChartData: [],
            lineChart: this.getLineChartConfig(this.uniqueOpens),
            pieChart: this.getPieChartConfig(this.pieChartData),
        };
    },

    methods: {
        getCampaignStats() {
            this.uniqueOpens = this.campaignStatsDetails.time_wise_email_opens.map((e) => {
                    const dateParts = e[0].split(/[- :]/); // split date and time parts using regex
                    const year = parseInt(dateParts[0]); // extract the year and parse it as an integer
                    const month = parseInt(dateParts[1]) - 1; // extract the month and subtract 1 to get a zero-based index
                    const date = parseInt(dateParts[2]); // extract the day of the month
                    const hours = parseInt(dateParts[3]); // extract the hours
                    const minutes = parseInt(dateParts[4]); // extract the minutes
                    const seconds = parseInt(dateParts[5]); // extract the seconds
                    return [
                        Date.UTC(year, month, date, hours, minutes, seconds),
                        e[1],
                    ];
                });
            console.log(" => this.unique Opens: ", this.uniqueOpens);
            this.lineChart = this.getLineChartConfig(this.uniqueOpens);
            this.pieChartData = [
                {
                    name: "Unique Opens",
                    y: this.campaignStatsDetails.pie_chart_data.unique_opens,
                },
                {
                    name: "Bounced",
                    y: this.campaignStatsDetails.pie_chart_data.bounced,
                },
                {
                    name: "Not Opened",
                    y: this.campaignStatsDetails.pie_chart_data.not_opened,
                },
            ];
            this.pieChart = this.getPieChartConfig(this.pieChartData);
        },
        getLineChartConfig(data) {
            const lineChart = {
                title: {
                    text: "First Day Stats",
                    align: "left",
                },

                credits: {
                    enabled: false // This will remove the HighCharts.com text
                },

                yAxis: {
                    title: {
                        text: "Number of Emails",
                    },
                    tickInterval: 1
                },

                xAxis: {
                    title:{
                        text:"Time"
                    },
                    type: "datetime",
                    dateTimeLabelFormats: {
                        hour: "%H:%M",
                    },
                },

                legend: {
                    layout: "vertical",
                    align: "right",
                    verticalAlign: "middle",
                },

                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                        pointStart: 9,
                    },
                },

                series: [
                    {
                        data: data,
                    },
                ],

                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                legend: {
                                    layout: "horizontal",
                                    align: "center",
                                    verticalAlign: "bottom",
                                },
                            },
                        },
                    ],
                },
            };
            return lineChart;
        },
        getPieChartConfig(data) {
            const pieChart = {
                chart: {
                    type: "pie",
                    height: "250px",
                },
                title: {
                    text: `Email Stats`,
                },
                credits: {
                    enabled: false // This will remove the HighCharts.com text
                },
                colors: ["#6AC259", "#E87D10", "#10D1E8"],
                series: [
                    {
                        name: "Data",
                        data: data,
                    },
                ],
            };
            return pieChart;
        },
    },

    created() {
        if(this.campaignStatsDetails) console.log(" => campaign Stats Details: ", this.campaignStatsDetails);
        this.getCampaignStats();
    },
};
</script>

<style scoped>
strong {
    font-weight: bold;
}
</style>
