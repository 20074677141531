<template>
    <div>
        <div class="rovlin-prod-thumbnail-card card" role="button">
            <img :src="url" class="image" @click="clicked()" />
            <div class="rovlin-prod-info-block">{{ name }}</div>
            <div class="rovlin-prod-info-tag">
                <span v-if="tag">#{{ tag }}</span>
                <span class="spacer"></span>
                <div class="d-flex justify-content-between align-items-center" style="position: relative;">
                    <i class="bi bi-three-dots-vertical" @click="this.showMenu = !this.showMenu;"
                        aria-hidden="true"></i>

                    <!-- Dropdown menu -->
                    <div class="dropdown-menu" :class="[{ 'show': showMenu }]" aria-labelledby="templateMenu"
                        style="top:15px;width: 50px;" @mouseleave="this.showMenu = false;">
                        <li class="d-flex dropdown-item" style="padding:4px 10px" @click="showModal">
                            <i class="bi bi-info-circle-fill"></i>
                            <span class="ms-2" type="button">View Info</span>
                        </li>
                        <li v-if="tag" class="d-flex dropdown-item" style="padding:4px 10px"
                            @click="deleteAction($event)">
                            <i class="bi bi-trash-fill"></i>
                            <span class="ms-2" type="button">Delete</span>
                        </li>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" :class="{ show: modalShow, 'd-block': modalShow }" tabindex="-1" role="dialog"
            id="newModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Template Preview</h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body p-4 border-bottom">
                        <div class="btn-group btn-group-toggle d-flex justify-content-center mb-3"
                            data-bs-toggle="buttons">
                            <button class=" btn rounded-4 btn-secondary"
                                :class="{ active: this.overlayClass == 'rovlin-prod-android-overlay' }"
                                @click="changeToMobile('rovlin-prod-android-overlay')">
                                Mobile
                            </button>
                            <button class=" btn rounded-4 btn-secondary"
                                :class="{ active: this.overlayClass == 'rovlin-prod-desktop-overlay' }"
                                @click="changeToDesktop('rovlin-prod-desktop-overlay')">
                                Desktop
                            </button>
                        </div>
                        <div class="d-flex justify-content-center rovlin-prod-main-modal-container pt-3">
                            <img v-if="overlayClass === 'rovlin-prod-android-overlay'"
                                :src="require(`../../../../public/images/mobile-image.png`)" />
                            <img v-else :src="require(`../../../../public/images/desktop-image.png`)" />
                            <div :class="overlayClass">
                                <img :src="url" class="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utilsService from "../../../Services/utils/utils.service";
export default {
    props: {
        url: String,
        tag: String,
        name: String,
        containerWidth: Number,
    },
    data() {
        return {
            overlayClass: "rovlin-prod-android-overlay",
            viewClassName: "android",
            modalShow: false,
            uniqueId: "",
            showMenu: false
        };
    },

    created() {
        this.uniqueId = "#" + this.name;
    },
    methods: {
        validateInput(url) {
            if (!utilsService.isDefined(url)) throw Error(`url must be defined`);
        },
        showModal() {
            this.modalShow = true;
        },
        closeModal() {
            this.modalShow = false;
        },
        getCardWidth() {
            const defaultWidth = 211;
            const availableWidth = document.getElementsByClassName("main-content-container")[0].offsetWidth - 48;
            if (!availableWidth) return 0;
            const supportedCardCount = Math.floor(availableWidth / defaultWidth);
            const additionalWidth = (availableWidth - supportedCardCount * defaultWidth) / supportedCardCount;
            return defaultWidth + additionalWidth - 36;
        },
        clicked() {
            this.$emit("clicked");
        },
        deleteAction(event) {
            this.$emit("deleteAction", event);
        },
        showInfo(e) {
            e.stopPropagation();
            e.preventDefault();
        },
        changePreview(device) {
            if (device === "android")
                this.viewClassName = "android";
            else if (device === "iOS")
                this.viewClassName = "iOS";
            else
                this.viewClassName = "desktop";
        },
        changeToMobile(layout) {
            this.overlayClass = layout;
        },
        changeToDesktop(layout) {
            this.overlayClass = layout;
        },
    },
};
</script>

<style scoped>
.rovlin-prod-thumbnail-card {
    height: 287px;
    position: relative;
    /* overflow: hidden; */
}

.rovlin-prod-thumbnail-card img {
    width: 100%;
    height: calc(100% - 35px);
    object-fit: cover;
}

.rovlin-prod-thumbnail-card .rovlin-prod-info-block {
    width: 100%;
    height: 35px;
    font-size: 14px;
    text-align: center;
}

.rovlin-prod-thumbnail-card .rovlin-prod-info-tag {
    position: absolute;
    width: 100%;
    background-color: #0c2349;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    display: inline-flex;
}

.rovlin-prod-thumbnail-card .rovlin-prod-info-tag .fa-trash {
    color: #ff8686;
}

.rovlin-prod-thumbnail-card span.spacer {
    flex: 1 1 auto;
}

.rovlin-prod-thumbnail-card:not(:hover) .rovlin-prod-info-tag {
    display: none;
}

.rovlin-prod-thumbnail-card:hover .image {
    opacity: 0.3;
}

.rovlin-prod-android-overlay {
    position: absolute;
    width: 135px;
    height: 237px;
    top: 134px;
    overflow-y: auto;
    background-color: #616161;
}

.rovlin-prod-desktop-overlay {
    position: absolute;
    width: 348px;
    height: 216px;
    top: 114px;
    overflow-y: auto;
}

.rovlin-prod-main-modal-container {
    min-height: 330px;
    height: 330px;
}
</style>