<template>
    <div class="accordion custom-accordion mt-2" :id="'collapseLead' + node.name">
        <div class="accordion-item">
            <h2 class="accordion-header" style="display: flex; position: relative;">
                <button class="accordion-button" :class="{ 'collapsed': !isOpen }" type="button"
                    @click="$emit('toggle-accordion')">
                    <i :class="node.icon" class="bi-icon"></i>
                    {{ node.name }}
                </button>
                <button class="refresh-btn" @click="refreshLeads">
                    <i class="bi bi-arrow-clockwise"></i>
                </button>
            </h2>
            <div :id="'collapseLeads' + node.name"
                :class="['accordion-collapse', { 'collapse show': isOpen, 'collapse': !isOpen }]">
                <div class="accordion-body">
                    <ul class="list-group">
                        <li v-for="lead in filteredLeads" :key="lead.id" class="list-group-item" :draggable="true"
                            @dragstart="dragStartLead($event, lead)">
                            {{ lead.name }}
                            <i class="bi bi-plus add-btn" @click="addNode(lead)"></i>
                        </li>
                        <li class=" list-group-item list-group-item-action create-new-lead"
                            @click="openCreateLeadModal">
                            Create New Lead
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div v-if="isModalVisible" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ modalTitle }}</h5>
                        <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <EditLeads :isModal="true" @close="closeModal" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isModalVisible" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
import EditLeads from '../leads/EditLeads.vue';

export default {
    name: 'LeadsNode',
    props: ['node', 'leads', 'isOpen'],
    components: {
        EditLeads
    },
    data() {
        return {
            modalTitle: 'Create New Lead',
            isModalVisible: false,
        };
    },
    computed: {
        filteredLeads() {
            // Filter identities
            return this.leads.filter(lead => lead.status === "ACTIVE");
        }
    },
    methods: {
        dragStartLead(event, lead) {
            const nodeData = { name: lead.name, type: 'lead' };
            console.log("Dragging lead:", nodeData);
            event.dataTransfer.setData('application/json', JSON.stringify(nodeData));
            this.$emit('drag-start-lead', event, lead);
        },
        addNode(lead) {
            const nodeData = { name: lead.name, type: 'lead' };
            this.$emit('add-node', nodeData);
        },
        openCreateLeadModal() {
            this.modalTitle = 'Create New Lead';
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        refreshLeads() {
            this.$emit('refresh-leads');

        }
    }
};
</script>
<style scoped>
.bi-icon {
    margin-right: 10px;
}

.custom-accordion {
    width: 100%;
}

.accordion-collapse.collapse {
    display: none;
}

.accordion-collapse.show {
    display: block;
}

.accordion-item {
    margin-top: 10px;
    overflow-x: auto !important;
}

.create-new-lead {
    cursor: pointer;
    color: #007bff;
    text-align: center;
    font-weight: bold;
    background-color: aliceblue !important;
}

.create-new-lead:hover {
    background-color: #f8f9fa;
}

.modal-content {
    left: 243px !important;
    top: 100px !important;
    /* align-items: center !important; */
}

.accordion-item:last-of-type .accordion-button.collapsed {
    background-color: var(--bs-accordion-active-bg);
}
</style>
