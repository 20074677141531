<template>
    <div class="avatar-image">
        <div class="avatar-initial" :style="{
            width: size + 'px',
            height: size + 'px',
            lineHeight: size + 'px',
            fontSize: size / 2 + 'px'
        }">
            {{ getInitials(username) }}
        </div>
    </div>
</template>


<script>
export default {
    name: 'AvatarImage',
    props: {
        username: {
            type: String,
            default: 'AA'
        },
        size: {
            type: Number,
            default: 80
        }
    },
    data() {
        return {
            userInfo: null,
            initials: null
        }
    },
    computed: {
        user() {
            return this.$store.userInfo;
        }
    },
    watch: {
        user() {
            this.userInfo = this.$store.user;
        }
    },
    methods: {
        getInitials(name) {
            if (!name) return null;
            name = name?.trim();
            const initials = name.match(/\b\w/g) || [];
            return initials.join('').toUpperCase()[0];
        }
    }

}
</script>

<style>
.avatar-image {
    border-radius: 50%;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.avatar-image .avatar-initial {
    text-align: center;
    font-weight: 600;
    color: #0c244a;
    opacity: 0.8;
}
</style>
