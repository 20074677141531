import spreadsheetService from "./spreadsheet.service";
class MAPPINGService {
    headers = spreadsheetService.headers;
    mapData = [];
    sheetData = [];
    validatedData = [];
    availableKeys = [
        "Name",
        "LastName",
        "Email",
        "Phone",
        "Whatsapp Number",
        "Messenger",
    ];

    errorMsg = [
        { name: "Email Must Be Selected", shown: false },
        { name: "Phone Must Be Selected", shown: false },
    ];

    sheetHeaders = [];
    singleHeaders = [];

    setDefaultMappedDAta(data) {
        this.singleHeaders = data;
        this.availableKeys.map((item) => {
            this.mapData.push({ available: item, selected: "", error: false });
        });

        data.map((item) => {
            this.sheetHeaders.push({ value: item, taken: false });
        });
    }

    changeAvailableValues(dItem, dIndex, index, dataItem, clicked,dataKeys){
        const newDataKeys = dataKeys
        if (dataItem.length == 0) {
            newDataKeys[index]["selected"] = dItem.value;
            this.sheetHeaders[dIndex]["taken"] = true;
        } else {
            const revertValue = dataItem;
            newDataKeys[index]["selected"] = dItem.value;
            this.sheetHeaders[dIndex]["taken"] = true;
            this.filterAvailableKeys(revertValue);
        }
        this.mapData = newDataKeys
        if (!clicked == 0) {
            this.checkDataMapping();
        }
        return {
            data:newDataKeys,
            next:this.mapData[2].selected == '' ? false : true
        }
    }

    checkDataMapping() {
        for (const item of this.mapData) {
            if (item.available === "Email") {
                if (item.selected === "") {
                    item.error = true;
                } else {
                    item.error = false;
                }
            }
        }
    }

    filterAvailableKeys(revertValue) {
        for (const item of this.sheetHeaders) {
            if (item.value === revertValue) {
                item.taken = false;
            }
        }
    }

    revertMapData() {
        this.mapData = [];
        this.sheetHeaders = [];
    }

    validateKeys(dataKeys) {
        this.mapData = dataKeys;
        this.sheetData = spreadsheetService.sheetData;
        this.validatedData = this.sheetData.map((item) => {
            let obj = {};
            this.mapData.map((mItem) => {
                if (mItem.available && mItem.selected) {
                    obj[mItem.available] = item[mItem.selected];
                }
            });
            return obj;
        });
        return this.validatedData;
    }
}

export default new MAPPINGService();
