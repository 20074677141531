<template>
    <span class="fa bi" :class="icon" ref="popover" :data-bs-toggle="popover" :data-bs-content="content" :title="title" data-bs-placement="top"></span>
</template>

<script>
import { Popover } from 'bootstrap';

export default {
    props: ['content', 'title', 'icon'],
    name: 'PopoverVue',

    mounted() {
        this.popover = new Popover(this.$refs.popover, {
            container: 'body'
        });
    },

    beforeUnmount() {
        if (this.popover) {
            this.popover.dispose();
            this.popover = null;
        }
    },

    watch: {
        $route: function () {
            if (this.popover) {
                this.popover.dispose();
                this.popover = null;
            }
        }
    }
};
</script>

<style>
.popover-body {
    text-align: center !important;
}
</style>
