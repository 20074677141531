<script>
import introJs from "intro.js/intro.js";
import { createVNode, render } from "vue";
import { h } from "vue";

const intro = introJs();

const json = [
    {
        steps: [
            {
                element: "#Templates-1",
                intro: "You find all the templates here",
            },
            {
                element: "#template-title",
                intro: "the title of the templates",
            },
        ],
        nextLink: "/leads",
        currentLink: "/templates"
    },
    {
        steps: [
            {
                element: "#Leads-3",
                intro: "tap leads button to get the leads",
            },
            {
                element: "#leads-title",
                intro: "Leads Title",
            },
        ],
        nextLink: "/billing",
        currentLink: "/leads"
    },
    {
        steps: [
            {
                element: "#add-details",
                intro: "Add Your card details here",
            },
        ],
        nextLink: null,
        currentLink: "/billing"
    },
];

export default {
    data() {
        return {
            nextLink: null,
            currentIndex: 0,
        };
    },
    methods: {
        startIntro(newStep) {

            if (this.$store.state.introValue || localStorage.getItem("showIntro")) {
                this.$store.state.introValue = false;
                localStorage.setItem("showIntro", false)
            }

            intro.setOption("overlayOpacity", 0.5);
            intro.setOptions(newStep);

            intro.oncomplete(() => {
                this.$store.state.introSteps = this.$store.state.introSteps + 1;
                this.currentIndex = 0;
                if (this.nextLink !== null) {
                    this.$router.push({ path: this.nextLink });
                    setTimeout(() => {
                        this.newIntro();
                    }, 500);
                }
            });

            intro.onafterchange((element) => {
                console.log(" => on after change element: ", intro._currentStep, element);
                const currentStep = intro._currentStep;
                this.currentIndex = currentStep;
                this.getNewCustomElement();
            });

            intro.start()
            // if(this.currentIndex !== 0){
            //   intro.goToStep(0);
            // }
        },

        newIntro(backStep = null) {
            const newStep = this.getNewSteps(backStep);
            if (backStep == true) {                                                     // started intro when click back for zero 
                this.currentIndex = newStep.steps?.length - 1;
                console.log(" => current Index: ", this.currentIndex);
                setTimeout(() => {
                    this.startIntro(newStep);
                }, 500);

            } else {
                this.currentIndex = 0;
                this.startIntro(newStep);
            }
        },
        getNewSteps(backStep = null) {
            const obj = json[this.$store.state.introSteps];
            let nStep = {};
            nStep["steps"] = obj.steps;
            if (backStep == true) {                                                     // navigating to the behind step component 
                this.$router.push({ path: obj.currentLink });
                introJs().exit()
            }
            this.nextLink = obj.nextLink;
            return nStep;
        },
        getNewCustomElement() {
            const bContainer = document.querySelector(".introjs-tooltipbuttons");
            if (bContainer) {
                const vnode = createVNode(this.CustomButtonsFunctional);
                render(vnode, bContainer);
            }

        },
        CustomButtonsFunctional() {
            return h("div", { class: "w-100 d-flex justify-content-between h-100" }, [
                h(
                    "button",
                    {
                        class: " btn rounded-4 btn-secondary",
                        onClick: () => {
                            this.prevStep();
                        },
                    },
                    "Back"
                ),
                h(
                    "button",
                    {
                        class: " btn rounded-4 btn-primary",
                        onClick: () => {
                            this.nextStep();
                        },
                    },
                    "Next"
                ),
            ]);
        },

        customIndexing() {
            let indexValue = 0;
            const children = json
                .flatMap((stepVal) => stepVal.steps.map(() => (indexValue += 1)))
                .map((index) => {
                    return h(
                        "li",
                        {
                            class: "px-1",
                            style: "cursor:pointer",
                            onClick: () => this.nextStep(),
                        },
                        index.toString()
                    );
                });

            return h("ul", {}, children);
        },

        nextStep() {
            intro.nextStep();
        },
        prevStep() {
            if (this.currentIndex != 0) {
                intro.previousStep();
            }
            else {
                this.$store.state.introSteps -= 1;   // less the store value 
                this.newIntro(true);
            }
        },
    },
    mounted() { },
};
</script>

<style>
.introjs-nextbutton {
    display: none !important;
}

.introjs-prevbutton {
    display: none !important;
}

.introjs-bullets>ul:first-child {
    display: none !important;
}
</style>