class UTILSService {
    EMAIL_REGEX =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

    isDefined(value) {
        if (!value) return false;
        if (value == null || value == undefined) return false;
        return true;
    }

    checkLeadsName(checkValue, leadsGroups) {
        console.log(" => leadsGroup: ", leadsGroups);
        for (let i = 0; i < leadsGroups.length; i++) {
            if (leadsGroups[i].name == checkValue) {
                return false;
            }
        }
        return true;
    }
}

export default new UTILSService();
