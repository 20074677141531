<template>
    <div class="pb-4">
        <div class="d-flex justify-content-between rovlin-prod-temp-header rovlin-prod-page-header py-4"
            v-if="templates && (templates.length > 0 || !globalLength)">
            <h3 class="rovlin-prod-page-title" id="template-title">Your templates</h3>
            <div class="custom-button">
                <button class="btn btn-primary rounded-4">
                    <i class="bi bi-chevron"></i> Template Library
                </button>
            </div>
        </div>
        <Information info="No Template Found"
            v-if="!globalLength && (!templates || templates.length === 0) && !this.$store.state.isLoading" />
        <div class="custom-flexibility" :contentLength="templates ? templates.length : 0">
            <TemplateModalV2 :ShowTemplateModal="ShowTemplateModal" :handleClose="handleClose"
                :saveModalChanges="saveModalChanges" />
            <div v-for="(item, index) in templates" v-bind:key="index" :style="delayTemplateStyle(index)">
                <template-card :url="item.thumbnail_url || item.thumbnail" :name="item.name" :tag="item.tag"
                    @deleteAction="deleteTemplate($event, item)" @clicked="editTemplate(item)" />
            </div>
        </div>
        <div class="px-0">
            <div v-if="showTemplateLibrary"
                class="rovlin-prod-temp-header d-flex justify-content-between rovlin-prod-page-header py-3">
                <h5 class="rovlin-prod-page-title">Health</h5>
            </div>
            <div class="custom-flexibility" v-if="showTemplateLibrary">
                <div v-for="(template, index) in globalTemplates" :key="template.data"
                    :style="delayTemplateStyle(index)">
                    <TemplateCard :url="(template.thumbnail || template.thumbnail_url)" :name="template.name"
                        @clicked="editGlobalTemplate(template)" />
                </div>
            </div>
            <div v-if="showTemplateLibrary"
                class="rovlin-prod-temp-header d-flex justify-content-between rovlin-prod-page-header py-3">
                <h5 class="rovlin-prod-page-title">Medical</h5>
            </div>
            <div class="custom-flexibility" v-if="showTemplateLibrary">
                <div v-for="(template, index) in globalTemplates" :key="template.data"
                    :style="delayTemplateStyle(index)">
                    <TemplateCard :url="(template.thumbnail || template.thumbnail_url)" :name="template.name"
                        @clicked="editGlobalTemplate(template)" />
                </div>
            </div>
            <div v-if="showTemplateLibrary"
                class="rovlin-prod-temp-header d-flex justify-content-between rovlin-prod-page-header py-3">
                <h5>Trip</h5>
            </div>
            <div class="custom-flexibility" v-if="showTemplateLibrary">
                <div v-for="(template, index) in globalTemplates" :key="template.data"
                    :style="delayTemplateStyle(index)">
                    <TemplateCard :url="(template.thumbnail || template.thumbnail_url)" :name="template.name"
                        @clicked="editGlobalTemplate(template)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APIService from "@/Services/ApiService/apiService";
import TemplateCard from "./ThumbnailCardV2.vue";
import TemplateModalV2 from "./TemplateModalV2.vue";
import Information from "@/components/layouts/sub-components/Information.vue";
import SweetAlert from "../../../Services/sweetAlerts/SweetAlerts";
import introMixin from "@/components/shared/Intro/introMixin.vue";

export default {
    name: "Template-vue",
    mixins: [introMixin],
    components: {
        TemplateModalV2,
        TemplateCard,
        Information,
    },
    props: ['templatesLength'],
    data() {
        return {
            globalTemplates: [],
            showTemplateLibrary: true,
            ShowTemplateModal: false,
            name: "",
            templates: this.$store.state.templates,
            perPage: 8,
            currentPage: 1,
            totalPage: 1,
            pagination: 0,
            columnWidth: Math.floor((window.innerWidth - 278) / 230),
            globalLength: null,
            stepsData: {
                steps: [
                    {
                        element: '#Templates-1',
                        intro: 'You find all the templates here',
                    },
                    {
                        element: '#template-title',
                        intro: 'the title of the templates',
                    },
                ]
            },
        };
    },
    computed: {
        state() {
            return this.name.length >= 4 ? true : false;
        },
    },
    methods: {
        setGlobalLength(value) {
            this.globalLength = value.length
            console.log(" => this.globalLength: ", this.globalLength);
        },
        saveModalChanges(value) {
            console.log(" => value: ", value);
            this.ShowTemplateModal = false;
            let typeParam = null;
            if (value == 1) {
                typeParam = "draganddrop";
            } else if (value == 2) {
                typeParam = "customtemplate";
            }
            if (value) {
                this.$router.push({
                    name: "Template-Editor",
                    params: { templateId: "new" },
                    query: { templateIds: this.selectedTemplates, type: typeParam },
                });
                this.$store.state.TemplateName = "";
            }
        },
        handleClose() {
            this.ShowTemplateModal = false;
        },
        getName: function () {
            this.$store.state.TemplateName = this.name;
        },
        toggleTemplateLibrary() {
            this.showTemplateLibrary = !this.showTemplateLibrary;
            this.$emit('toggle-template-library', this.showTemplateLibrary);
        },
        newTemplate() {
            this.ShowTemplateModal = true;
        },
        loadGlobalTemplates() {
            APIService.loadGlobalTemplate().then((templates) => {
                this.globalTemplates = templates?.data.filter((item) => item.name);
                this.setGlobalLength(this.globalTemplates);
            })
        },
        editTemplate(item) {
            this.$store.state.TemplateName = item.name;
            this.$router.push({
                name: "Template-Editor",
                params: { templateId: item.id },
            });
        },
        editGlobalTemplate(item) {
            this.$store.state.TemplateName = item.name;
            this.$router.push({ name: "Template-Editor", params: { templateId: 'new' }, query: { content: item.content } });
        },
        getTemplates() {
            this.$store.state.isLoading = true;
            APIService.getTemplates(this.pagination)
                .then((res) => {
                    this.totalPage = res.data.Total_templates;
                    const arr = [];
                    for (let ele in res.data.All_templates) {
                        arr.push(res.data.All_templates[ele]);
                    }
                    this.$store.state.templates = res.data.Total_templates ? arr : [];
                    this.templates = this.$store.state.templates;
                    this.$store.state.isLoading = false;
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        deleteTemplate(event, item) {
            event.preventDefault();
            event.stopPropagation();
            SweetAlert.Confirmation({ title: 'Are you sure want to delete?' }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        template_id: item.id,
                    };
                    this.$store.state.isLoading = true;
                    APIService.deleteCampaignTemplate(data)
                        .then(() => {
                            this.$store.state.isLoading = false;
                            this.getTemplates();
                        })
                        .catch(() => {
                            this.$store.state.isLoading = false;
                            this.$store.state.toastMessage = "Template is already in use in a Campaign";
                        })
                        .finally(() => {
                            this.$store.state.toastMessage = "";
                            this.$store.state.isLoading = false;
                        });
                }
            });
        },
        handlePageChange(event) {
            this.currentPage = event;
            this.pagination = (event - 1) * 8;
            this.getTemplates();
        },
        delayTemplateStyle(index) {
            const delay = index * 300;
            return {
                animationDelay: `${delay}ms`,
            };
        },
    },
    mounted() {
        console.log("new console")
        const showIntro = localStorage.getItem("showIntro")
        if (this.$store.state.introValue && showIntro == "true") {
            this.newIntro()
        }
    },
    created() {
        this.getTemplates();
        this.loadGlobalTemplates();
    },
};
</script>

<style scoped>
.card.active {
    border: #b8b8dd solid 2px;
    background-color: #b8b8dd;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
}

.rovlin-prod-temp-header {
    margin: 0px 18px;
}

.delete_template {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #ec7211;
    display: none;
}

.middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    color: #ffffff;
    left: 15px;
    top: 10px;
    font-size: 14px;
    font-weight: 600;
}

.no-data-found {
    margin: 0px 18px;
    padding: 30px 50px;
    min-width: 97%;
    text-align: center;
}

.card-img-top {
    object-fit: contain;
    background: #e7e7e7;
}

.card-main-block {
    margin: 0 15px;
    break-inside: avoid;
}

.custom-flexibility {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    margin-right: 8px;
}

.content {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
}

.divider {
    border-bottom: 1px solid lightgray;
    margin-bottom: 0.5rem;
}

.text-truncate {
    padding: 2px 10px;
}

.card:hover .delete_template {
    display: block;
}

.card-header {
    padding: 10px;
    padding-left: 0;
    font-size: 18px;
}

.pagination {
    margin: 10px auto;
}

.color-change .page-item.active .page-link {
    color: #fff;
    background-color: #dd6b11;
    border-color: #007bff;
}

.card-main-block {
    margin: 0 15px;
    margin-bottom: 20px;
}

.card:hover .delete_template {
    display: block;
}

.card-header {
    padding: 10px;
    padding-left: 0;
    font-size: 18px;
}
</style>
