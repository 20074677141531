<template>
    <div class="react-prod-stepper-box">
        <div class="top">
            <div class="divider-line" :style="{ width: `${(100 / (steps.length) * (steps.length - 1)) - 10}%` }"></div>
            <div class="steps-wrapper">
                <template v-if="topButtons">
                    <div v-if="currentStep.index > 0" class="react-prod-stepper-button-top previous" @click="backStep()">
                        <i class="bi bi-arrow-left"></i>
                    </div>
                </template>

                <div v-for="(step, index) in steps" :key="index" :class="['step', isStepActive(index, step)]"
                    :style="{ width: `${100 / steps.length}%` }">
                    <div class="circle">
                        <i class="material-icons bi md-18" :class="step.completed ? 'bi-check-lg' : step.icon"></i>
                    </div>
                    <div class="step-title">
                        <h5>{{ step.title }}</h5>
                        <h6 class="step-subtitle">{{ step.subtitle }}</h6>
                    </div>
                </div>
                <div v-if="topButtons" :class="['react-prod-stepper-button-top next', !canContinue ? 'deactivated' : '']"
                    @click="nextStep()">
                    <i class="material-icons bi bi-arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="p-2 pt-3">
            <transition :enter-active-class="enterAnimation" :leave-active-class="leaveAnimation" mode="out-in">

                <component :nextStep="canContinue" :is="stepComponent.value" :clicked="clicked" :showModal="showModal"
                    :clickedNext="nextButton[currentStep.name]" @can-continue="proceed" @share-data="shareData"
                    @change-next="changeNextBtnValue" :current-step="currentStep"></component>

            </transition>
        </div>
        <div :class="['bottom', 'only-next']">

            <button class=" btn rounded-4 btn-secondary" @click="nextStep()">
                {{ nextStepText() }} <span><i class="material-icons bi bi-arrow-right  ms-2"></i></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {

   
    props: {
        handleClose: {
            type: Function
        },
        showModal: {
            type: Boolean
        },
        stepComponent: {
            type: Object
        },
        locale: {
            type: String,
            default: "en"
        },
        topButtons: {
            type: Boolean,
            default: false
        },
        steps: {
            type: Array,
            default: function () {
                return [
                    {
                        icon: "mail",
                        name: "first",
                        title: "Sample title 1",
                        subtitle: "Subtitle sample"
                    },
                    {
                        icon: "report_problem",
                        name: "second",
                        title: "Sample title 2",
                        subtitle: "Subtitle sample"
                    }
                ];
            }
        },
        keepAlive: {
            type: Boolean,
            default: true
        },
        reset: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            clicked: 0,
            currentStep: {},
            previousStep: {},
            nextButton: {},
            canContinue: false,
            finalStep: false,
            keepAliveData: this.keepAlive
        };
    },
    computed: {
        enterAnimation() {
            if (this.currentStep.index < this.previousStep.index) {
                return "animated quick fadeInLeft";
            } else {
                return "animated quick fadeInRight";
            }
        },
        leaveAnimation() {
            if (this.currentStep.index > this.previousStep.index) {
                return "animated quick fadeOutLeft";
            } else {
                return "animated quick fadeOutRight";
            }
        }
    },
    methods: {
        nextStepText() {
            return (this.currentStep.index == this.steps.length - 1 ? 'Finish' : 'Next')
        },
        isStepActive(index) {
            if (this.currentStep.index === index) {
                return "activated";
            } else {
                return "deactivated";
            }
        },
        activateStep(index, back = false) {
            if (this.steps[index]) {
                this.previousStep = this.currentStep;
                this.currentStep = {
                    name: this.steps[index].name,
                    index: index
                };
                if (index + 1 === this.steps.length) {
                    this.finalStep = true;
                } else {
                    this.finalStep = false;
                }
                if (!back) {
                    this.$emit("completed-step", this.previousStep);
                }
            }
            this.$emit("active-step", this.currentStep);
        },
        nextStepAction() {
            this.nextButton[this.currentStep.name] = true;
            if (this.canContinue) {
                if (this.finalStep) {
                    this.$emit("stepper-finished", this.currentStep);
                    // this.handleClose()
                }
                let currentIndex = this.currentStep.index + 1;
                this.activateStep(currentIndex);
            }
            this.canContinue = false;
            this.$forceUpdate();
        },
        nextStep() {

            if (this.canContinue == false) {
                this.clicked++;
                return
            }
            else {
                if (!this.$attrs || !this.$attrs['before-next-step']) {
                    this.nextStepAction();
                }
                this.canContinue = false;
                this.clicked = 0;
                this.$emit("before-next-step", { currentStep: this.currentStep }, (next = true) => {
                    this.canContinue = true;
                    if (next) {
                        this.nextStepAction()
                    }
                });
            }
        },
        backStep() {
            this.$emit("clicking-back");
            let currentIndex = this.currentStep.index - 1;
            if (currentIndex >= 0) {
                this.activateStep(currentIndex, true);
            }
        },
        proceed(payload) {
            this.canContinue = payload.value;
        },
        shareData(payload) {
            console.log(" => data in horizontal stepper: ", payload);
            this.$emit("send-data", payload)
        },
        changeNextBtnValue(payload) {
            this.nextButton[this.currentStep.name] = payload.nextBtnValue;
            this.$forceUpdate();
        },
        init() {
            // Initiate stepper
            this.activateStep(0);
            this.steps.forEach(step => {
                this.nextButton[step.name] = false;
            });
        }
    },
    watch: {
        reset(val) {
            if (!val) {
                return;
            }
            this.keepAliveData = false;
            this.init();
            this.previousStep = {};
            this.$nextTick(() => {
                this.keepAliveData = this.keepAlive;
                this.$emit('reset', true);
            });
        },
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>
/* fallback */
@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    src: local("Material Icons"), local("MaterialIcons-Regular"),
        url(https://fonts.gstatic.com/s/materialicons/v17/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format("woff2");
}
.react-prod-stepper-box {
    background-color: white;
    min-height: 200px;
}
.react-prod-stepper-box .top {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}
.react-prod-stepper-box .top .react-prod-stepper-button-top {
    z-index: 20;
    padding: 0.4rem;
    border-radius: 100rem;
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.react-prod-stepper-box .top .react-prod-stepper-button-top.next {
    border: 2px solid #3383c8;
    color: #3383c8;
    right: 1%;
}
.react-prod-stepper-box .top .react-prod-stepper-button-top.next.deactivated {
    border: 2px solid #ccc !important;
    color: #ccc;
    cursor: not-allowed !important;
}
.react-prod-stepper-box .top .react-prod-stepper-button-top.previous {
    color: #333;
    left: 1%;
}
.react-prod-stepper-box .top .divider-line {
    border-bottom: 1px solid #ccc;
    height: 2px;
    position: absolute;
}
@media (max-width: 767px) {
    .react-prod-stepper-box .top .divider-line {
        width: 90%;
    }
}
.react-prod-stepper-box .top .steps-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 95%;
    left: 0;
    padding: 2% 4%;
}
@media (max-width: 767px) {
    .react-prod-stepper-box .top .steps-wrapper {
        width: 90%;
        justify-content: center;
    }
}
.react-prod-stepper-box .top .steps-wrapper .step {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
@media (max-width: 767px) {
    .react-prod-stepper-box .top .steps-wrapper .step {
        width: 100% !important;
    }
}
@media (max-width: 767px) {
    .react-prod-stepper-box .top .steps-wrapper .step.deactivated {
        display: none;
    }
}
.react-prod-stepper-box .top .steps-wrapper .step.deactivated .circle i {
    background-color: #bbb !important;
}
.react-prod-stepper-box .top .steps-wrapper .step.deactivated .step-title {
    opacity: 0.35;
}
.react-prod-stepper-box .top .steps-wrapper .step .circle {
    margin-bottom: 7px;
    padding: 0 1rem;
    background-color: white;
}
.react-prod-stepper-box .top .steps-wrapper .step .circle i {
    font-size: 16px;
    background-color: #5a4fdc;
    color: #fff;
    border-radius: 100rem;
    padding: 5px;
}
.react-prod-stepper-box .top .steps-wrapper .step .step-title {
    position: absolute;
    top: 90%;
    width: 100%;
}
.react-prod-stepper-box .top .steps-wrapper .step .step-title h1,
.react-prod-stepper-box .top .steps-wrapper .step .step-title h2,
.react-prod-stepper-box .top .steps-wrapper .step .step-title h3,
.react-prod-stepper-box .top .steps-wrapper .step .step-title h4,
.react-prod-stepper-box .top .steps-wrapper .step .step-title h5 {
    margin: 0 0 0.2rem 0;
    color: #333;
    font-weight: bold;
}
.react-prod-stepper-box .top .steps-wrapper .step .step-title .step-subtitle {
    font-weight: lighter;
    margin: 0;
    color: #555;
}
.react-prod-stepper-box .content {
    margin: 1.1rem 0;
}
.react-prod-stepper-box .bottom {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
}
.react-prod-stepper-box .bottom.only-next {
    justify-content: flex-end;
}
.react-prod-stepper-box .bottom .stepper-button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.react-prod-stepper-box .bottom .stepper-button.next {
    background-color: #3383c8;
    color: white;
}
.react-prod-stepper-box .bottom .stepper-button.next.deactivated {
    background-color: #ccc !important;
    cursor: not-allowed !important;
}
.react-prod-stepper-box .bottom .stepper-button.previous {
    color: #333;
}

</style>