<template>
    <div class="d-flex position-relative align-items-center">
        <div id="left" ref="leftIcon" class="rovlin-prod-carousel-icon" @click="prevCard"><i class="bi bi-chevron-left"></i></div>
        <div class="d-flex rovlin-prod-carousel-content-wrapper" ref="contentWrapper">
            <slot></slot>
        </div>
        <div id="right" ref="rightIcon" class="rovlin-prod-carousel-icon" @click="nextCard"><i class="bi bi-chevron-right"></i></div>
    </div>
</template>

<script>
export default {
    name: 'CardCarousel',
    props: ["contentLength",],
    data() {
        return {
            isDragging: false,
        };
    },
    methods: {
        prevCard() {
            const templateBox = this.$refs.contentWrapper;
            if (templateBox) {
                templateBox.scrollLeft -= 320;
                setTimeout(() => this.handleIcons(), 500);   // calling in 500 millisecond
            }
        },
        nextCard() {
            const templateBox = this.$refs.contentWrapper;
            if (templateBox) {
                templateBox.scrollLeft += 320;
                setTimeout(() => this.handleIcons(), 500);   // calling in 500 millisecond
            }
        },
        handleIcons() {
            const templateBox = this.$refs.contentWrapper;
            if (templateBox) {
                const leftIcon = this.$refs.leftIcon;
                const rightIcon = this.$refs.rightIcon;
                let scrollValue = Math.round(templateBox.scrollLeft + 1);
                let maxScrollWidth = templateBox.scrollWidth - templateBox.clientWidth;
                console.log(templateBox.scrollLeft, scrollValue, maxScrollWidth);
                leftIcon.style.display = scrollValue > 1 ? 'flex' : 'none';
                rightIcon.style.display = (maxScrollWidth >= 17 && maxScrollWidth > scrollValue) ? 'flex' : 'none';
                console.log(" => right: ", rightIcon.style.display);
            }
        },
        // handleMouseDown() {
        //   console.log('mouseDown');
        //   this.isDragging = true;
        // },
        // handleTouchMove(e) {
        //   console.log('move');
        //   const contentWrapper = this.$refs.contentWrapper;
        //   const touchDeltaX = e.touches[0].clientX - e.touches[0].pageX;
        //   contentWrapper.scrollLeft += touchDeltaX;
        // },
        // handleMouseUp() {
        //     console.log('mouseUp');
        //   this.isDragging = false;
        // },

    },
    mounted() {
        this.$nextTick(() => {
            if (this.contentLength >= 1) {
                this.handleIcons();
            }
        });
    },
    watch: {
        contentLength: function (newVal) {
            console.log(" => newVal: ", newVal);
            if (newVal >= 1)
                setTimeout(() => {
                    this.handleIcons()
                }, 1000);
        }
    }
}
</script>

<style>
.rovlin-prod-carousel-content-wrapper {
    /* width: 1200px; */
    width: calc(100vw - 403px);
    overflow-x: hidden;
    scroll-behavior: smooth;
    margin: 0px 65px;
}

.rovlin-prod-carousel-icon {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c2349;
    color: #fff;
    z-index: 1000;
}

.rovlin-prod-carousel-icon:first-child {
    left: 0px;
    display: none;
}

.rovlin-prod-carousel-icon:last-child {
    right: 0px;
    display: none;
}

.rovlin-prod-carousel-content-wrapper .thumbnail-card:first-child {
    margin-left: 0px;
}

/* @media screen and (max-width: 769px) {
  .rovlin-prod-carousel-icon {
    display: none;
  }
} */
</style>
