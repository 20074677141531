<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-between rovlin-prod-temp-header rovlin-prod-page-header py-4">
      <h3 class="rovlin-prod-page-title" id="Widgets-title">Widgets</h3>
      <div>
        <button class="btn btn-primary rounded-4" role="button" @click="$refs.openModal.click()">Add widget</button>
      </div>
    </div>

    <div class="alert alert-primary p-0 rounded mb-1"
      style="position: static; background-color: #f1faff; border-color: #0073bb;">
      <p class="m-0 d-flex align-items-center">
        <i class="bi bi-info-circle-fill mx-2" style="color: #0073bb;"></i> <span style="color: black; font-size: 14px;"> Manage your
          widgets efficiently. Add, edit, or delete widgets as needed.</span>
      </p>
    </div>

    <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" ref="openModal"
      data-bs-target="#exampleModal">
      Launch demo modal
    </button>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Widget</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeModal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-3">
            <div class="row">
              <div class="col-6">
                <label for="end-date" class="form-label">Name</label>
                <input type="text" class="form-control" v-model="newWidget.name" required
                  placeholder="Enter Widget Name" />
              </div>
              <div class="col-6">
                <label for="email" class="form-label"> Type</label>
                <select class="form-select" id="email" name="email" v-model="newWidget.type" required>
                  <option value="NewsLetter">News Letter</option>
                  <option value="PromotionBanner">Promotion Banner</option>
                </select>
              </div>
              <div class="col-6">
                <label for="email" class="form-label mt-3"> Leads Group</label>
                <select class="form-select" id="email" name="email" v-model="newWidget.groupId" required>
                  <option v-for="(option, index) in groupList" :key="index" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- Button to trigger the lead group modal -->
            <button type="button" class="btn btn-primary rounded-4" @click="openLeadGroupModal"
              style="position: absolute; left:8px;">Create new Lead group</button>
            <button type="button" class="btn btn-secondary rounded-4" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary rounded-4"
              v-if="newWidget.name && newWidget.type && newWidget.groupId" @click="setNewWidget(newWidget)">Save
              changes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for Dynamic Component -->
    <div class="modal fade" id="dynamicModal" tabindex="-1" aria-labelledby="dynamicModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="dynamicModalLabel">Create New Lead Group</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- Inside the template where the dynamic modal component is used -->
            <component :is="currentComponent" :isModal="true" @close="onModalClose"
              @leadGroupCreated="onLeadGroupCreated" />

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="(item, index) in widgets" :key="index" class="col-6">
        <EditNl v-if="item.type.toUpperCase() == 'NEWSLETTER'" :updateWidget="updateWidget" :deleteWidget="deleteWidget"
          :widget="item" :saveWidget="saveWidget" :apiKey="apiKey" :editWidget="saveEditedWidget" :index="index"
          class="col-lg-6" />
        <EditPb v-if="item.type.toUpperCase() == 'PROMOTIONBANNER'" :updateWidget="updateWidget"
          :deleteWidget="deleteWidget" :widget="item" :saveWidget="saveWidget" :apiKey="apiKey"
          :editWidget="saveEditedWidget" :index="index" class="col-lg-6" />
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import APIService from "../../../Services/ApiService/apiService";
import EditNl from "./Templates/Newsletter/EditNl.vue"
import EditPb from "./Templates/PromoBanner/EditPb.vue"
import { pbConfig } from "./Templates/PromoBanner/pbTemplate";
import { nlConfig } from "./Templates/Newsletter/nlTemplate";
import apiService from "../../../Services/ApiService/apiService";

// Dynamically import the EditLeads component
const EditLeads = () => import(/* webpackChunkName: "edit-leads" */ '../leads/EditLeads.vue');

export default {
  name: "Widgets-vue",
  data() {
    return {
      showNl: false,
      showPb: false,
      nlConfig: nlConfig,
      pbConfig: pbConfig,
      groupList: [],
      widgets: [],
      newWidget: {
        name: "",
        type: "NewsLetter",
        groupId: null
      },
      currentComponent: null, // Store the component to be rendered dynamically
    }
  },
  components: {
    EditNl, EditPb
  },
  methods: {
    setNewWidget(newWidget) {
      this.widgets.push({
        id: null,
        name: newWidget.name,
        type: newWidget.type,
        config: newWidget.type.toUpperCase() == 'NEWSLETTER' ? nlConfig : pbConfig,
        leadsGroup: newWidget.groupId,
        saved: false,
        widget_url: ""
      })
      this.newWidget = { name: "", type: "", groupId: null };
      this.$refs.closeModal.click()
    },
    saveEditedWidget(newConfig, index) {
      console.log("newConfig : saving Stage", newConfig, index)
      if (newConfig && index) this.widgets[index].config = newConfig;
    },

    saveWidget(obj, index) {
      console.log("index", index)
      this.$store.state.isLoading = true
      APIService.createWidgets(obj).then(() => {
        this.$store.state.toastMessage = "Widget Added  Successfully";
      }).catch((error) => {
        const errorMessage = { ...error };
        this.$store.state.toastMessage =
          errorMessage.response.data.message || errorMessage.response.data;
      }).finally(() => {
        this.$store.state.isLoading = false;
        this.widgets.splice(index, 1);
        this.widgets = this.widgets.filter((item) => item.saved != true);
        this.getWidget();
      })
    },
    async openLeadGroupModal() {
      const component = await EditLeads(); // Load the component
      this.currentComponent = component.default || component;
      const modalElement = document.getElementById('dynamicModal');
      const modalInstance = new Modal(modalElement);
      modalInstance.show();
    },
    closeModal() {
      const modalElement = document.getElementById('dynamicModal');
      const modalInstance = Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    },
    onModalClose() {
      this.closeModal();
      this.getGroups();
    },
    updateWidget(obj, id) {
      this.$store.state.isLoading = true
      APIService.updateWidget(obj, id).then(() => {
        this.$store.state.toastMessage = "Widget Updated  Successfully";
      }).catch((error) => {
        const errorMessage = { ...error };
        this.$store.state.toastMessage =
          errorMessage.response.data.message || errorMessage.response.data;
      }).finally(() => {
        this.$store.state.isLoading = false;
        this.getWidget();
      })
    },
    deleteWidget(id) {
      this.$store.state.isLoading = true
      APIService.deleteWidget(id).then(() => {
        this.$store.state.toastMessage = "Widget Deleted  Successfully";
      }).catch((error) => {
        const errorMessage = { ...error };
        this.$store.state.toastMessage =
          errorMessage.response.data.message || errorMessage.response.data;
      }).finally(() => {
        this.$store.state.isLoading = false;
        this.getWidget();
      })
    },
    getWidget() {
      this.$store.state.isLoading = true
      this.widgets = [];
      APIService.getWidgets().then((res) => {
        this.addOldWidgets(res.data)
      }).finally(() => {
        this.$store.state.isLoading = false
      })
    },
    // Functions for setting the Data
    addOldWidgets(data) {
      console.log('res.data', data);
      this.testLoading = true
      Object.keys(data.All_widgets).forEach((key) => {
        const item = data.All_widgets[key];
        this.setOldWidgetItem(item);
      });
      this.testLoading = false
    },
    async setOldWidgetItem(item) {
      try {
        const widgetConfig = await apiService.getWidgetData(item.config_url);
        if (widgetConfig) {
          const obj = {
            id: item.id,
            name: item.name,
            type: item.tag,
            config: widgetConfig.data,
            saved: true,
            leadsGroup: null,      // Might be changes in future
            widget_url: item.widget_url
          }
          this.widgets.push(obj);
        }
      } catch (error) { console.error('Error fetching widget data:', error) }
    },
    onLeadGroupCreated() {
      // Fetch the updated list of groups or add the newly created group to groupList
      this.getGroups(); // Assuming this method fetches the updated groups list
    },
    getGroups() {
      this.$store.state.isLoading = true,
        APIService.getGroups()
          .then((res) => {
            const leadGroup = res.data.filter(
              (item) => item.leadsCount > 0 && item.status === "ACTIVE"
            );
            this.groupList = APIService.formatOptionsWithKeyValue(
              leadGroup,
              "Group",
              "name",
              "uuid"
            );
            console.log(" => this.groupList: ", this.groupList);
          })
          .catch(() => {
            this.$store.state.toastMessage = "Something went wrong!";
          })
          .finally(() => {
            this.$store.state.toastMessage = "";
            this.$store.state.isLoading = false;
          });
    },
  },
  mounted() {
    this.getWidget();
    this.getGroups();
  }
}
</script>

<style>
/* Add custom styles here if needed */
</style>
