import Swal from "sweetalert2";

class SweetAlert {
    static defaultValue = {
        title: `Are you sure want to perform this action `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
    };

    static Confirmation(value = {}) {
        const options = Object.assign(SweetAlert.defaultValue, value);
        return Swal.fire(options);
    }
}

export default SweetAlert;
