<template>
  <div class="row m-0 rovlin-prod-document-main-block">
    <div class="col-lg-8 mb-5">
      <div class="row rovlin-prod-rovlin-prod-page-header mt-4 py-4 no-gutters">
        <h3 class="col rovlin-prod-rovlin-prod-page-title">Documentation for use this tool</h3>
      </div>
      <div class="accordion" id="accordion">
        <div class="card mb-2" v-for="(item, index) in documentation" :key="index">
          <div class="card-header p-0" id="heading{{ index }}">
            <button class=" btn rounded-4 btn-info text-left w-100 py-3" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + index" aria-expanded="false" aria-controls="collapse{{ index }}"
              @click="item.open = !item.open">
              {{ item.question }}
              <div class="rovlin-prod-right">{{ btnTxt(item.open) }}</div>
            </button>
          </div>
          <div :id="'collapse' + index" class="collapse" :class="{ show: item.open }" aria-labelledby="heading{{ index }}"
            data-bs-parent="#accordion">
            <div class="card-body">
              <p class="card-text text-left">{{ item.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Document-vue",
  data() {
    return {
      documentation: [
        {
          question: "Template",
          answer: `With Templates, you can build professional marketing emails without having any HTML skills. Templates helps you make attractive, professional-looking email campaigns that fit any screen size or mobile device.`,
          open: true,
        },
        {
          question: "Targets",
          answer: `Multi lead feature and a system that’s been proven to improve conversion rates, most powerful tool for generating leads of any kind. Add to that our competitively priced packages and it’s easy to see why our customer leads are quickly becoming the go-to source for companies who want more qualified prospects and better conversion rates.`,
          open: false,
        },
        {
          question: "Leads",
          answer: `ImportLeads is an automated email tool that takes the pain out of email marketing. With Importleads, you’ll be able to create campaigns with a wide variety of templates. Campaigns can be set up for high visibility and open rates so you can promote products or raise awareness about new products and services.`,
          open: false,
        },
        {
          question: "Analytics",
          answer: `A marketing dashboard can be used for a variety of purposes, including analyzing marketing efforts, measuring success, and making business decisions. It includes an analytics feature that benefits marketers, dashboard provides insight into campaign effectiveness.`,
          open: false,
        },
      ],
    };
  },
  methods: {
    btnTxt(open) {
      return open ? "˅" : "^";
    },
  },
};
</script>
<style scoped>
.rovlin-prod-document-main-block {
  height: calc(100vh - 110px);
  overflow-y: auto;
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
}

.btn-info {
  color: #fff;
  border-color: rgb(12, 36, 74);
  background-color: rgb(12, 36, 74);
}

.rovlin-prod-rovlin-prod-page-header .rovlin-prod-rovlin-prod-page-title {
  margin: auto;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 1.625rem;
}

.rovlin-prod-right {
  float: right;
}
</style>