import { createRouter, createWebHistory } from "vue-router";

import   Dashboard        from "../components/pages/Dashboard/Dashboard.vue";
import Template from "../components/pages/templates/Template.vue";
import TemplateV2 from "../components/pages/templates/TemplateV2.vue"
// import   TemplateEditor   from "../components/pages/templates/TemplateEditor.vue";
import   Campaigns        from "../components/pages/campaign/Campaigns.vue";
import   Leads            from "../components/pages/leads/Leads.vue";
import   Launch           from "../components/pages/launch/Launch.vue";
import   History          from "../components/pages/History/History.vue";
import   Trash            from "../components/pages/Trash/Trash.vue";
import   Feedback         from "../components/pages/Feedback/Feedback.vue";
import Support from "../components/pages/Support/supportHistory.vue";

import   Transaction      from "../components/pages/Transaction/Transaction.vue";
import   Identity         from "../components/pages/Identity/Identity.vue";
import   AddCampaigns     from "../components/pages/campaign/AddCampaigns.vue";
import   AddCard          from "../components/pages/payment/AddCard.vue";
import   ImagePicker      from "../components/pages/templates/ImagePicker.vue";
import   UserProfile      from "../components/pages/Profile/UserProfile.vue";
import   AllLeads         from "../components/pages/leads/AllLeads.vue";
import   EditLeads        from "../components/pages/leads/EditLeads.vue";
import   EditLeadsGroup   from "../components/pages/leads/EditLeadsGroup.vue";
import   LeadsInfo        from "../components/pages/leads/LeadsInfo.vue";
import   Document         from "../components/pages/document/Document.vue";
import   Home             from "../components/pages/Home/Home.vue";
import   Billing          from "../components/pages/payment/Billing.vue";
import   DeveloperSettings from "../components/pages/Developer/Developer-Settings.vue";
import   Widgets           from "../components/pages/Widgets/Widgets.vue"
import   TemplateEditor    from "../components/pages/templates/TemplateEditor.vue";
import   PrivacyPolicy     from "../components/layouts/sub-components/PrivacyPolicy.vue"
import Terms from "../components/layouts/sub-components/Terms.vue"
import CustomDrawflow from "../components/pages/CustomDrawflow/CustomDrawflow.vue"
// import   Test               from "../components/pages/Test/Test.vue"
import   Integration       from "../components/pages/app&Integration/Integration.vue"
import IntegrationCallback from "../components/pages/app&Integration/callback/IntegrationCallback.vue"



const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: Dashboard,
        },
        // {
        //     path:"/test",
        //     component: Test
        // },
        {
            path: "/privacy",
            component: PrivacyPolicy,
        },
        {
            path: "/terms",
            component: Terms,
        },
        {
            path: "/document",
            name: "document",
            component: Document,
        },
        {
            path: "/templates",
            name: "createTemplate",
            component: Template,
        },
        {
            path: "/templates-v2",
            name: "newTemplate",
            component: TemplateV2,
        },
        {
            path: "/templates/editor/:templateId",
            name: "Template-Editor",
            component: TemplateEditor,
        },
        {
            path: "/templates/editor/:templateId",
            component: TemplateEditor,
        },
        {
            path: "/campaigns",
            name: "campaigns",
            component: Campaigns,
        },
        {
            path: "/leads",
            name: "leads",
            component: Leads,
        },
        {
            path: "/launch",
            name: "launch",
            component: Launch,
        },
        {
            path: "/history",
            name: "history",
            component: History,
        },
        {
            path: "/trash",
            name: "trash",
            component: Trash,
        },
        {
            path: "/support",
            name: "support",
            component: Support,
        },
        {
            path: "/drawflow",
            name: "drawflow",
            component: CustomDrawflow,
        },
        {
            path: "/feedback",
            name: "feedback",
            component: Feedback,
        },
        {
            path: "/transaction-history",
            name: "transaction-history",
            component: Transaction,
        },
        {
            path: "/billing",
            name: "billing",
            component: Billing,
        },
        {
            path: "/billing/add-card",
            name: "add-card",
            component: AddCard,
        },
        {
            path: "/billing/add-card/:cardId",
            name: "payment-profile-card",
            component: AddCard,
        },
        {
            path: "/identity",
            name: "email-service",
            component: Identity,
        },
        {
            path: "/campaigns/:id",
            name: "addCampaigns",
            component: AddCampaigns,
        },
        {
            path: "/campaigns/flow",
            name: "campaign-flow",
            component: CustomDrawflow,
        },
        {
            path: "/share",
            name: "sampleSharedModal",
            component: ImagePicker,
        },
        {
            path: "/user-profile",
            name: "UserProfileComponent",
            component: UserProfile,
        },
        {
            path: "/developer-setting",
            name: "DeveloperSettings",
            component: DeveloperSettings
        },
        {
            path: "/widgets",
            name: "Widgets-vue",
            component: Widgets
        },
        {
            path: "/leads/viewAll",
            name: "viewAll",
            component: AllLeads,
        },
        {
            path: "/leads/:group/:groupName/:id",
            name: "addUpdateLeads",
            component: EditLeads,
        },
        {
            path: "/leads/create-leads",
            name: "createLeads",
            component: EditLeads,
        },
        {
            path: "/leads/manage-group/:id/:name",
            name: "manageGroup",
            component: EditLeadsGroup,
        },
        {
            path: "/leads/:group/:groupName",
            name: "leads-group",
            component: LeadsInfo,
        },
        {
            path: "/help",
            name: "home-block",
            component: Home,
        },
        {
            path: "/integration",
            name: "integration",
            component: Integration,
        },
        {
            path: "/integration/callback",
            name: "IntegrationCallback",
            component: IntegrationCallback,
        }
    ],
    linkActiveClass: "active",
});

export default router;
