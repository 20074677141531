<template>
  <div fluid class="main-content-container container-fluid p-0 px-4">
    <div class="container-fluid rovlin-prod-page-header py-4 p-0">
      <div class="row no-gutters">
        <div class=" col-sm-4 text-center text-sm-left mb-4 ">
          <h2 class="rovlin-prod-page-title primary-color text-sm-left">How it Works</h2>
        </div>

        <!-- Start 3 columns -->
        <div class="rovlin-prod-home">

          <div class="jumbotron card rovlin-prod-stats-small rovlin-prod-stats-small--1 card card-small">
            <div class="row" id="how_works">
              <div class="col-sm"
                style="text-align: center; border-right: 2px solid; border-right-color:#D3D3D3; padding: 20px">
                <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 30%;"
                  src="@/assets/icons/template.png" alt="Shards Dashboard">
                <h4 style="font-weight: 800">Step 1<br>Select Template</h4>
                <p>Select from hundreds of ready to use templates or create a custom email template for your audience.</p>
              </div>
              <div class="col-sm"
                style="text-align: center; border-right: 2px solid; border-right-color:#D3D3D3; padding: 20px">
                <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 30%;"
                  src="@/assets/icons/Campaigns.png" alt="Shards Dashboard">
                <h4 style="font-weight: 800">Step 2<br>Create Campaign</h4>
                <p>Promote business, products and services to your customers to increase engagement and conversions.</p>
              </div>
              <div class="col-sm" style="text-align: center; padding: 20px">
                <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 30%;"
                  src="@/assets/icons/launch.png" alt="Shards Dashboard">
                <h4 style="font-weight: 800">Step 3<br>Launch</h4>
                <p>Achieve your sales and marketing goals, unlike social media messages getting lost. Keep track of
                  engagements, campaign effectiveness with user analytics.</p>
              </div>
            </div>
          </div>
        </div>


        <!-- <div class="col-sm-4 text-center text-sm-left mb-4">
          <h2 class="rovlin-prod-page-title text-primary text-sm-left ">Converting leads into customers</h2>
        </div>



        <div class="rovlin-prod-home">
          <div class="jumbotron card rovlin-prod-stats-small rovlin-prod-stats-small--1 card card-small">
            <template>
              Automate marketing activities in minutes, to capture leads and retain customers.
            </template>


            <p class="mb-2">
              Create campaigns, analyze customer behavior and campaign performance.
            </p>
            <button type="button" class=" btn rounded-4 btn-primary btn-lg mt-4" style="width:12%!important; color: white;"
              @click="openCampaigns()">Get Started</button>

          </div>
        </div> -->

        <div class="col-sm-4 text-center text-sm-left mb-4" id="rovlin-prod-p-top">
          <h2 class="rovlin-prod-page-title primary-color text-sm-left ">Benefits</h2>
        </div>
        <!-- Start 2 columns -->
        <div class="rovlin-prod-home">

          <div class="jumbotron card rovlin-prod-stats-small rovlin-prod-stats-small--1 card card-small">
            <div class="row" id="how_works">
              <div class="row">
                <div class="col-lg-3 col-sm-12 col-md-6">
                  <img src='images/undraw_Online_messaging_re_qft3.svg' class="mdi mdi-collage img-fluid"
                    style="max-width: 80%;">
                </div>
                <div class="align-self-center col-sm-12 col-md-6 col-lg-3" sm="12" md="6" lg="3">
                  <h4>Easy To Use</h4>
                  <p>Create beautiful landing pages to improve customer engagement</p>
                </div>
                <div class="mt-3 col-sm-12 col-md-6 col-lg-3">
                  <img src='images/undraw_Messaging_app_re_aytg.svg' class="mdi mdi-collage img-fluid"
                    style="max-width: 80%;">
                </div>
                <div class=" align-self-center mt-3 col-lg-3 col-sm-12 col-md-6">
                  <h4>Schedule Campaigns</h4>
                  <p>Send Personalized Email from Promotions and Sales Events</p>
                </div>
              </div>
            </div>
            <div id="how_works" class="row m-top">
              <div class="row">
                <div class="col-lg-3 col-sm-12 col-md-6">
                  <img src='images/undraw_Envelope_re_f5j4.svg' class="mdi mdi-collage img-fluid" style="max-width: 80%;">
                </div>
                <div class=" align-self-center col-lg-3 col-sm-12 col-md-6">
                  <h4>Grow Your Revenue</h4>
                  <p>Improve Your ROI with few easy and simple steps</p>
                </div>
                <div class="mt-3 col-lg-3 col-sm-12 col-md-6">
                  <img src='images/undraw_Social_life_re_x7t5.svg' class="mdi mdi-collage img-fluid"
                    style="max-width: 80%;">
                </div>
                <div class="align-self-center mt-3 col-lg-3 col-sm-12 col-md-6">
                  <h4>Market Like A Pro</h4>
                  <p>Smooth Implementation Of Marketing Campaigns</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End 2 columns -->

        <div class="pb-3 pe-3">
            <div class="row">
                <!-- View Full Report -->
                <div class="text-end view-report">
                    <router-link to="/document" class="navigate-link">Read
                        Document <i class="bi bi-arrow-right-short" style='font-size:24px; vertical-align:sub;'></i></router-link>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'home-block',
  components: {
  },
  methods: {
    openCampaigns() {
      this.$router.push({ name: "campaigns" });
    },
  }
}
</script>
<style>
.rovlin-prod-home {
  margin-top: 20px;
}

.rovlin-prod-home .jumbotron {
  padding: 3rem 2rem !important;
}

.rovlin-prod-home .jumbotron {
  background-color: #ffffff !important;
}

.rovlin-prod-stats-small {
  min-height: 8.7rem;
  overflow: hidden !important;
}

.jumbotron {
  padding: 38px 42px;
  margin-bottom: 2rem;
  background-color: #eceeef;
  border-radius: 0.5rem;
}



#rovlin-prod-p-top {
  padding-top: 25px;
}

.rovlin-prod-page-header .rovlin-prod-page-title {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 0;
}

.primary-color {
  color: #0c244a !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
}</style>