<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-between rovlin-prod-temp-header rovlin-prod-page-header py-4">
            <h3 class="rovlin-prod-page-title" id="Widgets-title">App & Integration</h3>
        </div>
        <div class="custom-style">
            <div class="card" v-for="(app, index) in appData" :key="index"
                :data-title="app.name.charAt(0).toUpperCase() + app.name.slice(1)">
                <img :src="app.logoUrl" class="card-img-top" alt="app logo">
                <div class="tooltip-wrapper">
                    <template v-if="app.connectionStatus === 'connected'">
                        <span class="connected-label">Connected</span>
                    </template>
                    <template v-else>
                        <div>
                            <i class="bi bi-plus connect-icon" @click="connectApps(app.name)"></i>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <ConfigModal ref="modalRef" />
    </div>
</template>

<script>
import configModal from "./configModal.vue";
import APIService from "../../../Services/ApiService/apiService";
import { integrationConfig } from "./integrationData";

export default {
    name: "integration-vue",
    data() {
        return {
            integrationData: integrationConfig,
            appData: null,
        };
    },
    components: {
        ConfigModal: configModal,
    },
    methods: {
        connectApps(name) {
            this.$store.state.isLoading = true;
            APIService.connectApps(name)
                .then((res) => {
                    console.log("we got this response from salesforce", res);
                    if (res?.data?.url) {
                        console.log("comes here ");
                        window.location.href = res?.data?.url;
                    } else {

                        const app = this.appData.find(app => app.name === name);
                        if (app) {
                            app.connectionStatus === "connected"
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error connecting app:", error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        fetchApp() {
            this.$store.state.isLoading = true;
            APIService.fetchLeadsData()
                .then((res) => {
                    this.appData = res.data;
                    console.log("Fetched app data:", this.appData);
                })
                .catch((error) => {
                    console.error("Error fetching app data:", error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
    },
    mounted() {
        this.fetchApp();
    },
};
</script>

<style scoped>
.custom-style {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 20px !important;
    margin-right: 8px;
}

.card {
    position: relative;
    height: 287px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.card:hover {
    transform: translateY(-5px);
}

.card:hover::after {
    opacity: 1;
    visibility: visible;
}

.card-img-top {
    height: 167px !important;
    padding: 10px !important;
    object-fit: contain;
}

.tooltip-wrapper {
    position: absolute;
    top: -7px;
    right: 3px;
    z-index: 1;
}

.card::after {
    content: attr(data-title);
    position: absolute;
    bottom: 10px;
    /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    background-color: #878484;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 1;
}

.connect-icon {
    cursor: pointer !important;
    font-size: 36px !important;
    color: #007bff !important;
    transition: color 0.3s !important;
    font-weight: bold !important;
}

.connect-icon:hover {
    color: #0056b3 !important;
}

.connected-label {
    position: absolute !important;
    top: 15px !important;
    right: 12px !important;
    background-color: #28a745 !important;
    color: white !important;
    padding: 5px 12px !important;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
}

@media (max-width: 576px) {
    .custom-style {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
}
</style>
