import utilsService from "../../../../../Services/utils/utils.service";

export default function getPbTemplate(pbStyle,apiKey, leadsGroup) {
 return `
 <!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
        <style>
            * {
                font-family: Arial, Helvetica, sans-serif;
            }
            .pb-main {
                background:${pbStyle.background};
                border-radius: 5px;
                padding: 19px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: ${pbStyle.direction};
            }
            .first-title {
                font-size: ${pbStyle.first.size}px;
                margin: 0;
                text-transform: uppercase;
                color: ${pbStyle.first.color};
            }
            .discount-title {
                font-size:${pbStyle.second.size}px;
                font-family: Arial, Helvetica, sans-serif;
                margin: 0;
                margin-top: 9px;
                text-transform: uppercase;
                color: ${pbStyle.second.color};
            }
            .third-title {
                font-size:${pbStyle.third.size}px;
                margin: 0;
                text-transform: uppercase;
                color: ${pbStyle.third.color};
                margin-top: 9px;
            }
            .pb-form-control {
                width: ${pbStyle.input.width}px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
                border: 1px solid  #e9edf6;
                color: #333335 !important;
                background-color: #ffffff !important;
                font-size: 0.875rem;
                font-weight: 400 !important;
                line-height: 1.6;
                border-radius: 0.35rem;
                padding: 0.5rem 0.85rem;
            }

            .pb-form-control:hover:active {
                outline: 0 !important;
            }

            .pb-submit-button {
                background: ${pbStyle.button.color}!important;
                border: none;
                color: white;
                padding: 8px;
                font-size:13px;
                border-radius: 10px;
                cursor: pointer;
                height:37px
            }
            .pb-is-invalid {
                border-color: black !important;
            }

            .pb-invalid-feedback {
                color: white;
                font-size: 13px;
                display: none;
                margin-top: 4px
            }
        </style>
    </head>
    <body>
        <div class="pb-main" onclick="navigateToLink('${pbStyle.link}')">
            <p class="first-title">${pbStyle.first.text}</p>
            <p class="discount-title">${pbStyle.second.text}</p>
            <p class="third-title">${pbStyle.third.text}</p>
            <div style="margin-top: 14px;margin-bottom: 4px;display: flex">
            <div>  <input type="text" class="pb-form-control" id="emailInput" placeholder="${pbStyle.input.text}" />
            <div class="pb-invalid-feedback" id="invalidMsg">Invalid email address</div></div>
                <button class="pb-submit-button" style="margin-left: 14px;" onclick="submitEmail()">${pbStyle.button.text}</button>
            </div>
        </div>
        <script>
            function navigateToLink(path) {
                console.log("link")
                if (path) window.location.href = path;
            }
            function submitEmail() {
                const emailInput = document.getElementById('emailInput');
                const feedbackElement = document.getElementById('invalidMsg'); // Invalid feedback div

                const email = emailInput.value;

                if (!email || !validateEmail(email)) {
                    emailInput.classList.add('pb-is-invalid');
                    feedbackElement.style.display = 'block';
                    return;
                }
                emailInput.classList.remove('pb-is-invalid');
                feedbackElement.style.display = 'none';

                fetch('${process.env.VUE_APP_ROOT_API}collect-leads/${leadsGroup || ''}', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'apiKey':'${apiKey}'
                    },
                    body: JSON.stringify({ email: email }),
                })
                .then(response => response.json())
                .then(data => {
                    console.log('Data:', data);
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert('Failed to submit the email.');
                });
            }

            function validateEmail(email) {
                const re =  ${utilsService.EMAIL_REGEX};
                return re.test(String(email).toLowerCase());
            }
        </script>
    </body>
</html>

 `
}

export const pbConfig = {
    background: "#d04356",
    direction:"center",
    link:'',
    first: {
        text: "Member Save",
        color: "#f2efef",
        size: "19",
    },
    second: {
        text: "20% off",
        color: "#ffffff",
        size: '43',
    },
    third: {
        text: "One full - price item",
        color: "#f2efef",
        size: '19',
    },
    input: {
        text: "Your Email",
        width: "180",
    },
    button: {
        text: "Submit",
        color: "#4CAF50",
    },
};
