import { createApp } from 'vue';
import 'regenerator-runtime/runtime';
import App from './App.vue';
import router from './router';
import store from './store';
import awsConfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import AmplifyVue from '@aws-amplify/ui-vue';
import VueGoodTablePlugin from 'vue-good-table-next';
import VueHtml2Canvas from 'vue-html2canvas';
import HighchartsVue from 'highcharts-vue'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import '@aws-amplify/ui-vue/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import './assets/style.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vue3-toastify/dist/index.css';
import 'bootstrap';
import 'intro.js/introjs.css';
import 'drawflow/dist/drawflow.min.css'
import SearchBar from './components/layouts/sub-components/SearchBar.vue';
Amplify.configure(awsConfig);
const app = createApp(App);
app.use(AmplifyVue);
app.use(store).use(VueGoodTablePlugin).use(VueLoading).use(HighchartsVue).use(VueHtml2Canvas).use(router).component('SearchBar', SearchBar).mount('#app');
