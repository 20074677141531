<template>
    <div class="accordion custom-accordion mt-2" :id="'collapseTemplate' + node.name">
        <div class="accordion-item">
            <h2 class="accordion-header" style="display: flex; position: relative;">
                <button class="accordion-button" :class="{ 'collapsed': !isOpen }" type="button"
                    @click="$emit('toggle-accordion')">
                    <i :class="node.icon" class="bi-icon"></i>
                    {{ node.name }}
                </button>
                <button class="refresh-btn" @click="refreshTemplate">
                    <i class="bi bi-arrow-clockwise"></i>
                </button>
            </h2>
            <div :id="'collapseTemplateInner' + node.name"
                :class="['accordion-collapse', { 'collapse show': isOpen, 'collapse': !isOpen }]">
                <div class="accordion-body">
                    <ul class="list-group">
                        <li v-for="template in templates" :key="template.id" class="list-group-item" :draggable="true"
                            @dragstart="dragStart($event, template)">
                            {{ template.name }}
                            <i class="bi bi-plus add-btn" @click="addNode(template)"></i>
                        </li>
                        <li class="list-group-item list-group-item-action create-new-template"
                            @click="createNewTemplate">
                            Create New Template
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TemplateNode',
    props: ['node', 'templates', 'isOpen'],
    methods: {
        dragStart(event, template) {
            const nodeData = { name: template.name, type: 'template' };
            this.$emit('drag-start', event, nodeData);
        },
        addNode(template) {
            const nodeData = { name: template.name, type: 'template' };
            this.$emit('add-node', nodeData);
        },
        createNewTemplate() {
            const newTemplateId = 'new';
            this.$router.push({
                name: 'Template-Editor',
                params: { templateId: newTemplateId },
                query: { mode: 'create' }
            });
        },
        refreshTemplate() {
            this.$emit('refresh-template');

        }
    }
};
</script>

<style scoped>
.bi-icon {
    margin-right: 10px !important;
}

.custom-accordion {
    width: 100%;
}

.accordion-collapse.show {
    display: block;
}

.accordion-item {
    margin-top: 10px;
    overflow-x: auto !important;
}

.create-new-template {
    cursor: pointer;
    color: #007bff;
    text-align: center;
    font-weight: bold;
    background-color: aliceblue;
}

.create-new-template:hover {
    background-color: #f8f9fa;
}
.accordion-item:last-of-type .accordion-button.collapsed {
    background-color: var(--bs-accordion-active-bg);
}
</style>
