<template>
    <div class="d-flex justify-content-end mb-3 py-4">
        <button class="btn btn-primary rounded-4" @click="openSupportModal">Create Ticket</button>
    </div>
    <div class="container-fluid main-content-container py-4">
        <!-- Support component as a modal -->
        <div v-if="isSupportModalOpen" class="modal fade show d-block" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Create Support Ticket</h5>
                        <button type="button" class="close" @click="closeSupportModal"
                            style="height: 30px;width: 30px; border-radius: 50px;border: none;">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <Support />
                    </div>
                </div>``
            </div>
        </div>

        <VueGoodTable :rows="supportHistoryData" :columns="columns" :pagination-options="{
            enabled: true,
            perPageDropdownEnabled: false,
            perPage: 10
        }" :sort-options="{ enabled: true }">
            <template #table-row="{ row, column }">
                <span v-if="column.field === 'date'">{{ formatDate(row.date) }}</span>
                <span v-if="column.field === 'status'">
                    <span :class="row.status === 'Resolved' ? 'text-success' : 'text-warning'">{{ row.status }}</span>
                </span>
                <span v-if="column.field === 'action'">
                    <button class="btn btn-outline-primary btn-sm" @click="viewDetails(row)">View Details</button>
                </span>
            </template>
        </VueGoodTable>
    </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import Support from "./Support.vue";

export default {
    name: "SupportHistoryComponent",
    components: {
        VueGoodTable,
        Support
    },
    data() {
        return {
            columns: [
                { label: "S No.", field: "SNo",},
                { label: "Date", field: "date", },
                { label: "Ticket ID", field: "ticketId",},
                { label: "Subject", field: "subject",},
                { label: "Status", field: "status",},
                { label: "Action", field: "action" },
            ],
            supportHistoryData: [],
            isSupportModalOpen: false, // State to track modal visibility
        };
    },
    methods: {
        openSupportModal() {
            this.isSupportModalOpen = true;
        },
        closeSupportModal() {
            this.isSupportModalOpen = false;
        }
    }
};
</script>

<style scoped>
.rovlin-prod-page-title {
    font-weight: 500;
    color: #606266;
    font-size: 20px !important;
}

.btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd;
}

.btn-outline-primary:hover {
    background-color: #0d6efd;
    color: white;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    margin-top: 10%;
}
::v-deep .vgt-responsive {
    padding: 0rem 1rem !important;
    background-color: white;
}

::v-deep table.vgt-table {
    border: none;
}

::v-deep .vgt-table.bordered th {
    border: none;
}

::v-deep .vgt-table th {
    border-bottom: 1px solid #ddd !important;
    /* border-top: 1px solid #ddd !important; */
}

::v-deep .vgt-table.bordered td {
    border: none;
}

::v-deep .vgt-table td {
    border-bottom: 1px solid #ddd !important;
    padding: 0.5em 1em 0.5em 0.5em !important;
}

::v-deep .vgt-wrap__footer {
    border: none !important;
    background: #fff !important;

}

::v-deep .vgt-table thead th {
    background: #fff !important;
}
</style>
