const XLSX = require("xlsx");
import datamappingService from "./datamapping.service";

class SHEETService {
    matchArr = [];
    sheetData = [];
    validKeys = ["Name", "LastName", "Email", "Phone"];
    headers = [];
    groupName = null;

    readFile(selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
            let fileType = selectedFile.name.split(".").pop();
            let workbook;
            if (
                fileType === "xlsx" ||
                fileType === "xls" ||
                fileType === "csv" ||
                fileType === "ods" ||
                fileType === "vcf"
            ) {
                const fileData = new Uint8Array(reader.result);
                workbook = XLSX.read(fileData, { type: "array" });
            }
            const sheetName = workbook.SheetNames[0];
            const newSheetData = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheetName]
            );
            const sheet = workbook.Sheets[sheetName];
            const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];
            datamappingService.setDefaultMappedDAta(headers);
            this.headers = headers;
            this.sheetData = newSheetData;
            if (!headers.length == 0) {
                this.groupName = selectedFile.name.split(".")[0];
                console.log(" => this.groupName: ", this.groupName);
                return true;
            }
        };
        reader.readAsArrayBuffer(selectedFile);
    }
    unselectFile() {
        this.sheetData = [];
        this.headers = [];
        this.groupName = null;
    }
}

export default new SHEETService();
