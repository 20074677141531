import spreadsheetService from "./spreadsheet.service";
import UTILSService from "./utils.service";
class IMPORTservice {
    mappedData = spreadsheetService.validatedData;
    validatedMappedData = [];
    dataError = null;

    validationCheck(data) {
        this.mappedData = data;
        this.dataError = this.checkMappedData(this.mappedData);
        return { check: this.dataError };
        // return { check:false,data:this.validatedMappedData}
    }

    checkMappedData(mappedData) {
        let totalError = false;
        this.validatedMappedData = mappedData.map((item) => {
            const emailCheck = !UTILSService.EMAIL_REGEX.test(item.Email);
            if (totalError == false && emailCheck == true) {
                totalError = emailCheck;
            }
            return { ...item, emailError: emailCheck };
        });
        return totalError;
    }

    skipRow(data, index) {
        data.splice(index, 1);
        return data;
    }

    skipAll() {
        this.validatedMappedData = this.validatedMappedData.filter(
            (item) => item.emailError != true
        );
        return this.validatedMappedData;
    }
}

export default new IMPORTservice();
