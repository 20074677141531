<template>
    <div>
        <TopNavbar class="top-navbar px-3" :changeSidebar="changeSidebar" :showSidebar="showSidebar" />
        <DropdownModal v-if="this.$store.state.showProfile" />
        <div class="container-fluid px-0">
            <div class="d-flex gap-2 " style="background-color: #F0F1F7">
                <LeftSidebar v-if="shouldShowSidebar" :changeSidebar="closeSidebar" :showSidebar="showSidebar"
                    class="left-sidebar" :class="showSidebar ? 'd-flex w-100' : 'd-none d-md-flex'" />
                <main role="main" class="flex-grow-1 main-height container-fluid" style="background-color: #F0F1F7"
                    :class="showSidebar && 'd-none'">
                    <slot></slot>
                </main>
            </div>
        </div>
        <FooterNavbar/>
    </div>
</template>


<script>

import LeftSidebar from './sub-components/LeftSidebar.vue'
import TopNavbar from './sub-components/TopNavbar.vue'
import DropdownModal from '../shared/Popper/DropdownModal.vue'
import FooterNavbar from './sub-components/FooterNavbar.vue'
export default {
    name: 'LayoutsMain',
    components: {
        LeftSidebar,
        TopNavbar,
        DropdownModal,
        FooterNavbar
    },
    data() {
        return {
            showSidebar: false
        }
    },
    computed: {
        shouldShowSidebar() {
            return this.$route.path !== '/campaigns/flow';
        }
    },
    methods: {
        changeSidebar() {
            this.showSidebar = !this.showSidebar;
            console.log(" => show Sidebar value changes: ", this.showSidebar);
        },
        closeSidebar() {
            this.showSidebar = false
        },
        updateShowSidebar() {
            if (this.showSidebar && window.innerWidth > 768) this.showSidebar = false
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateShowSidebar);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateShowSidebar);
    },
}
</script>

<style>
.left-sidebar {
    border-right: 1px solid #e3e3e3;
    height: calc(100vh - 92px);
    /* height: calc(100vh - 112px); */
    width: 215px;
}

.top-navbar {
    height: 58px;
    width: 100%;
}

.footer-block {
    height: 56px;
    width: 100%;
}

.main-height {
    overflow-y: auto;
    height: calc(100vh - 88px);
}

</style>
