import axios from "axios";
import { Auth } from "aws-amplify";
import Store from "@/store";

const apiInstance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
});


apiInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 403) {
            const jwtToken = await getJwtToken();
            Store.commit("setJwtToken", jwtToken);
            originalRequest.headers.authorizationToken = `${jwtToken}`;
            return apiInstance(originalRequest);
        }
        return Promise.reject(error);
    }
);

async function getJwtToken() {
    return Auth.currentSession().then(
        (data) => data.idToken.jwtToken
    );
}

export default apiInstance;
