<template>
    <div class="searchBox" ref="searchBox">
        <input type="text" class="searchInput" v-model="searchTerm" @keydown.down="onArrowDown" @keydown.up="onArrowUp"
            @keydown.enter="onEnter" ref="searchInput" :style="inputStyles" placeholder="Search..." />

        <div class="searchButton" @click.stop="toggleSearch">
            <i class="bi bi-search" :style="{ color: isSearchVisible ? 'rgb(185, 185, 185)' : 'white' }"></i>
        </div>
        <ul class="autocomplete-dropdown mt-2" container="body" v-if="showDropdown" ref="autocomplete">
            <li v-for="(item, index) in filteredItems" :key="index" @click="selectItem(item)"
                :class="{ active: index === activeIndex }">
                {{ item.name }}
            </li>
        </ul>

        <!-- Error message -->
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";

export default {
    data() {
        return {
            searchTerm: '',
            items: [],
            filteredItems: [],
            showDropdown: false,
            activeIndex: -1,
            debounceTimer: null,
            isSearchVisible: false,
            searchIconColor: 'white',
            errorMessage: '',
        };
    },
    computed: {
        inputStyles() {
            const isSmallScreen = window.innerWidth <= 600;
            if (this.isSearchVisible) {
                return {
                    width: isSmallScreen ? '75px' : '200px',
                    padding: '0 15px',
                };
            } else {
                return {
                    width: '0px',
                    padding: '0',
                };
            }
        },
    },
    methods: {
        debouncedFilterItems() {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(this.filterItems, 300);
        },
        filterItems() {
            if (this.searchTerm === '') {
                this.filteredItems = this.items;
                this.showDropdown = false;
            } else {
                this.callSearchAPI(this.searchTerm);
            }
            this.activeIndex = -1;
        },
        callSearchAPI(query) {
            APIService.searchAPI(query)
                .then((res) => {
                    console.log("Search API response:", res);
                    if (res?.data?.results && res.data.results.length > 0) {
                        this.filteredItems = res.data.results;
                        this.showDropdown = true;
                        this.errorMessage = '';
                    } else {
                        this.showDropdown = false;
                        this.filteredItems = [];
                        this.errorMessage = "No results found";
                        setTimeout(() => {
                            this.errorMessage = '';
                        }, 4000);
                    }
                    console.log("showDropdown:", this.showDropdown);
                    console.log("filteredItems:", this.filteredItems);
                })
                .catch(error => {
                    console.error("Error fetching search results:", error);
                    this.showDropdown = false;
                    this.filteredItems = [];
                    this.errorMessage = "Error generating search results";
                    setTimeout(() => {
                        this.errorMessage = '';
                    }, 5000);
                });
        },
        selectItem(item) {
            this.searchTerm = item.name;
            this.showDropdown = false;
        },
        onArrowDown() {
            if (this.activeIndex < this.filteredItems.length - 1) {
                this.activeIndex++;
            }
        },
        onArrowUp() {
            if (this.activeIndex > 0) {
                this.activeIndex--;
            }
        },
        onEnter() {
            if (this.activeIndex !== -1) {
                this.selectItem(this.filteredItems[this.activeIndex]);
            } else {
                this.callSearchAPI(this.searchTerm);
            }
        },

        toggleSearch(event) {
            event.stopPropagation();
            this.isSearchVisible = true;
            this.searchIconColor = 'rgb(185, 185, 185)';
            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.focus();
                }
            });
        },

        handleClickOutside(event) {
            const searchBoxRef = this.$refs.searchBox;

            if (this.isSearchVisible && searchBoxRef && !searchBoxRef.contains(event.target)) {
                this.isSearchVisible = false;
                this.showDropdown = false;
            }
        },
        updateInputStyles() {
            this.$forceUpdate();
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateInputStyles);
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateInputStyles);
        document.removeEventListener('click', this.handleClickOutside);
        clearTimeout(this.debounceTimer);
    }
};
</script>

<style scoped>
.searchBox {
    position: relative;
    background: white;
    height: 21px;
    border-radius: 40px;
    transition: width 0.4s, padding 0.4s;
}

.searchButton {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.4s;
    cursor: pointer;
}

.searchInput {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: black;
    font-size: 11px;
    transition: width 0.4s, padding 0.4s;
    line-height: 21px;
    width: 0px;
}

.autocomplete-dropdown {
    position: absolute;
    width: 225px !important;
    top: 40px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    padding-left: 0;
    list-style: none;
    z-index: 1;
}

.autocomplete-dropdown li {
    padding: 5px 10px;
    cursor: pointer;
}

.autocomplete-dropdown li.active {
    background-color: #f0f0f0;
}

.error-message {
    color: red;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;

}

@media (max-width: 600px) {
    .error-message {
        width: 100px !important;
        font-size: 10px;

    }

    .autocomplete-dropdown {
        width: 85px !important;
    }

}

.bi-search {
    color: black !important;
    font-size: 12px;
    position: relative;
}
</style>
