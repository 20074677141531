<template>
    <div class="container-fluid pb-4">
        <div class="row rovlin-prod-page-header py-4 m-0">
            <div class="rovlin-prod-page-title">
               Enter your feedback
            </div>
        </div>
        <div class="row m-0">
            <form class="col-sm-12 col-md-6 custom-new-card p-3" @submit.prevent="onSubmit" style="max-height: 340px;">
                <div class="mb-3">
                    <label class="sr-only" for="f2_Name">Name</label>
                    <input id="f2_Name" class="form-control mb-2 mr-sm-2 mb-sm-0" v-model="form.name" type="text"
                        placeholder="Ex. John Doe" required />
                </div>
                <!-- <div class="mb-3">
                    <label class="sr-only" for="f2_Email">Email Address</label>
                    <input id="f2_Email" class="form-control mb-2 mr-sm-2 mb-sm-0" v-model="form.email" type="email"
                        placeholder="email@example.com" required />
                </div> -->

                <div class="mb-3">
                    <label for="f2_Message" class="sr-only">Message</label>
                    <textarea id="f2_Message" class="form-control" v-model="form.message" placeholder="Enter something"
                        :rows="5" :max-rows="6" required>
                    </textarea>
                </div>

                <button type="submit" class=" btn rounded-4 btn-primary">
                    Submit feedback
                </button>
            </form>
            <!-- <div class="col-sm-12 col-md-6 d-flex justify-content-center">
                <img :src="require('../../../assets/feedback.png')" class="img-fluid" style="max-width: 500px;"/>
            </div> -->
        </div>
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";
import { Auth } from "aws-amplify";
export default {
    name: "FeedbackComponent",
    data() {
        return {
            user: {},
            form: {
                name: "",
                message: "",
            },
        };
    },
    computed: {},
    methods: {
        onSubmit() {
            const body = {
                name: this.form.name,
                email: this.user?.attributes?.email,
                message: this.form.message,
            };
            APIService.updateFeedback(body)
                .then(() => {
                    this.$store.state.toastMessage =
                        "Feedback submit successfully";
                    this.form.name = "";
                    this.form.message = "";
                })
                .catch((error) => {
                    const errorMessage = { ...error };
                    this.$store.state.toastMessage =
                        errorMessage.response.data.message ||
                        errorMessage.response.data;
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
    },
    created() {
        Auth.currentUserInfo().then((user) => {
            this.user = user;
        });
    },
};
</script>

<style scoped>
</style>
