<template>
  Privacy Policy
</template>

<script>
export default {
    name: 'PrivacyPolicy',
}
</script>

<style>

</style>
