import axios from "axios";

class ImageHandlerService {
    async downloadUrls(urls) {
        const result = [];
        if (!urls) return result;
        for (const url of urls) {
            const isValid = this.checkIsValidUrl(url);
            if (!isValid) {
                result.push({ url, base64: url });
                return;
            }
            const base64 = await this.downloadUrl(url);
            result.push({ url, base64 });
        }
        return result;
    }

    checkIsValidUrl(url) {
        try {
            new URL(url);
            return true;
        } catch (e) {
            return false;
        }
    }

    async downloadUrl(url) {
        return axios.get(url, { responseType: "blob" })
            .then(response => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(response.data);
                });
            })
            .catch(error => {
                console.warn("Axios request error:", error);
                return null; 
            });
    }
}

export default new ImageHandlerService();
