<template>
    <div :class="['accordion', 'custom-accordion', 'mt-2']">
        <div class="accordion-item">
            <h2 class="accordion-header" style="display: flex; position: relative;">
                <button class="accordion-button" :class="{ 'collapsed': !isOpen }" type="button"
                    @click="toggleAccordion" :aria-expanded="isOpen">
                    <i :class="node.icon" class="bi-icon"></i>
                    {{ node.name }}
                </button>
                <button class="refresh-btn" @click="refreshCampaigns">
                    <i class="bi bi-arrow-clockwise"></i>
                </button>
            </h2>
            <div :id="'collapseCampaign' + node.name"
                :class="['accordion-collapse', { 'collapse show': isOpen, 'collapse': !isOpen }]">
                <div class="accordion-body">
                    <div class="mb-1">
                        <label for="campaign-name" class="form-label">Campaign Name:</label>
                        <input type="text" placeholder="Enter Campaign Name" class="form-control" id="campaign-name"
                            v-model="campaignName" :disabled="isNonEditable" />
                        <div v-if="campaignNameError" class="text-danger">{{ campaignNameError }}</div>
                    </div>
                    <div class="mb-1">
                        <label for="subject" class="form-label">Subject:</label>
                        <input type="text" placeholder="Enter Subject" class="form-control" id="subject"
                            v-model="subject" :disabled="isNonEditable" />
                        <div v-if="subjectError" class="text-danger">{{ subjectError }}</div>
                    </div>
                    <div class="mb-1">
                        <label for="start-date" class="form-label">Start Date:</label>
                        <input type="date" class="form-control" id="start-date" v-model="startDate" :min="minStartDate"
                            @change="updateEndDateMin" @click.stop :disabled="isNonEditable" />
                    </div>
                    <div class="mb-1">
                        <label for="end-date" class="form-label">End Date:</label>
                        <input type="date" class="form-control" id="end-date" v-model="endDate" :min="minEndDate"
                            @click.stop :disabled="isNonEditable" />
                        <div v-if="endDateError" class="text-danger">{{ endDateError }}</div>
                    </div>
                    <div v-if="!isNonEditable && isEditMode" class="d-flex mt-2">
                        <button class="btn btn-primary" @click="updateNode">Update</button>
                        <button class="btn btn-secondary ms-2" @click="cancelEditMode">Cancel</button>
                    </div>
                    <button v-if="isFormComplete && !isEditMode && !isNonEditable" class="btn btn-primary mt-2"
                        draggable="true" @dragstart="handleNodeAction($event, 'drag')" @click="handleNodeAction()"
                        style="height: 30px; width: 30px;">
                        <i class="bi bi-plus-lg"></i>
                    </button>
                    <div class="divider my-3"></div>
                    <div class="campaigns-list-items">
                        <ul class="list-group">
                            <li v-for="campaign in campaigns" :key="campaign.id" class="list-group-item"
                                :draggable="true" @dragstart="dragStart($event, campaign)">
                                {{ campaign.name }}
                                <i class="bi bi-plus add-btn" @click="addCampaign(campaign, false, true)"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/eventBus';

export default {
    name: 'CampaignNode',
    props: ['node', 'editableNode', 'isOpen', 'campaigns'],
    data() {
        return {
            campaignName: '',
            subject: '',
            startDate: null,
            endDate: null,
            minStartDate: new Date().toISOString().split('T')[0],
            minEndDate: null,
            campaignNameError: '',
            subjectError: '',
            endDateError: '',
            isEditMode: false,
            isNonEditable: false, // Flag to control editability
            nodeId: null,
        };
    },
    watch: {
        editableNode: {
            handler(newVal) {
                console.log("CampaignNode: editableNode watcher triggered with value:", newVal);
                if (newVal) {
                    this.id = newVal.id;
                    this.campaignName = newVal.name;
                    this.subject = newVal.subject;
                    this.startDate = newVal.startDate;
                    this.endDate = newVal.endDate;
                    this.isEditMode = !newVal.isNonEditable; // Disable edit mode if non-editable
                    this.isNonEditable = newVal.isNonEditable || false;
                    this.openAccordion();
                } else {
                    this.isEditMode = false;
                }
            },
            deep: true
        }
    },
    computed: {
        isFormComplete() {
            return this.campaignName && this.subject && this.startDate && this.endDate && !this.campaignNameError && !this.subjectError && !this.endDateError;
        }
    },
    methods: {
        populateFieldsWithNodeData(nodeData) {
            console.log("CampaignNode: Received node data for editing:", nodeData);
            if (nodeData.id) {
                this.nodeId = nodeData.id;
                this.campaignName = nodeData.name;
                this.subject = nodeData.subject;
                this.startDate = nodeData.startDate;
                this.endDate = nodeData.endDate;
                this.isEditMode = !nodeData.isNonEditable; // Disable edit mode if non-editable
                this.isNonEditable = nodeData.isNonEditable || false;
            } else {
                console.log("CampaignNode: Node ID does not match, skipping update.");
            }
            this.openAccordion()
        },
        updateEndDateMin() {
            if (this.startDate) {
                const start = new Date(this.startDate);
                start.setDate(start.getDate() + 1);
                this.minEndDate = start.toISOString().split('T')[0];

                if (this.endDate && new Date(this.endDate) <= start) {
                    this.endDateError = "End date must be after the start date.";
                } else {
                    this.endDateError = '';
                }
            }
        },
        handleNodeAction(event = null, actionType = 'add') {
            const nodeData = {
                name: this.campaignName || this.node.name,
                subject: this.subject,
                startDate: this.startDate,
                endDate: this.endDate,
                icon: this.node.icon,
                type: 'campaign',
                isNonEditable: true, // Mark as non-editable
            };

            if (actionType === 'drag' && event) {
                event.dataTransfer.setData('application/json', JSON.stringify(nodeData));
            } else if (actionType === 'add') {
                this.$emit('add-node', nodeData);
            }

            this.isEditMode = false;
        },
        clearForm() {
            this.campaignName = '';
            this.subject = '';
            this.startDate = null;
            this.endDate = null;
            this.isEditMode = false;
            this.isNonEditable = false;
        },
        updateNode() {
            if (!this.isFormComplete || this.isNonEditable) {
                return;
            }

            const updatedNodeData = {
                id: this.nodeId,
                name: this.campaignName,
                subject: this.subject,
                startDate: this.startDate,
                endDate: this.endDate,
                icon: this.node.icon,
                type: 'campaign'
            };
            this.$emit('update-campaign', updatedNodeData);
            this.isEditMode = false;
            this.clearForm();
        },
        cancelEditMode() {
            this.isEditMode = false;
            this.clearForm();
        },
        openAccordion() {
            if (!this.isOpen) {
                this.$emit('toggle-accordion');
            }
        },
        toggleAccordion() {
            if (!this.isEditMode) {
                this.$emit('toggle-accordion');
            }
        },
        dragStart(event, campaign) {
            const nodeData = {
                id: campaign.id,
                name: campaign.name,
                startDate: campaign.s_date.split(" ")[0],
                endDate: campaign.e_date.split(" ")[0],
                type: 'campaign',
                isEditable: false,
                hideSubject: true
            };
            event.dataTransfer.setData('application/json', JSON.stringify(nodeData));
            this.$emit('drag-start', event, nodeData);
        },
        addCampaign(campaign, isEditable = false, hideSubject = true) {
            const nodeData = {
                id: campaign.id,
                name: campaign.name,
                subject: campaign.subject,
                startDate: campaign.s_date.split(" ")[0],
                endDate: campaign.e_date.split(" ")[0],
                icon: this.node.icon,
                type: 'campaign',
                isEditable: isEditable,
                hideSubject: hideSubject
            };
            this.$emit('add-node', nodeData);
        },
        refreshCampaigns() {
            this.$emit('refresh-campaigns');
        }
    },
    mounted() {
        eventBus.on('campaign-node-edit', this.populateFieldsWithNodeData);
        eventBus.on('campaign-node-dropped', this.clearForm);
        eventBus.on('campaign-node-dropped', () => {
            this.isEditMode = false; // Ensure buttons are hidden after drop
        });
        eventBus.on('edit-node', (node) => {
            if (node && node.id === this.node.id) {
                this.activateEditMode(node);
            }
        });
        // Listen for the clear-campaign-node-fields event
        eventBus.on('clear-campaign-node-fields', () => {
            if (!this.isEditMode) {
                this.clearForm(); // Clear the form fields only if not in edit mode
            }
        });

        // Listen for the clear-campaign-form event
        eventBus.on('clear-campaign-form', () => {
            this.clearForm();
        });
    },
    beforeUnmount() {
        eventBus.off('campaign-node-edit', this.populateFieldsWithNodeData);
        eventBus.off('campaign-node-dropped', this.clearForm);
        eventBus.off('edit-node');
        eventBus.off('clear-campaign-node-fields');
        eventBus.off('clear-campaign-form');
    }
};
</script>

<style scoped>
.accordion-button:focus {
    box-shadow: none;
}

.bi-icon {
    margin-right: 10px;
}

.custom-accordion {
    width: 100%;
}

.accordion-collapse.show {
    display: block;
}

.accordion-item {
    margin-top: 10px;
}

.accordion-body {
    padding: 1rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.text-danger {
    color: red;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: auto;
}

.ms-2 {
    margin-left: 10px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    background-color: var(--bs-accordion-active-bg);

}

.bi-plus-lg {
    display: flex !important;
    font-size: large !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: move !important;

}

.divider {
    border-bottom: 2px solid #dee2e6;
}

.list-group {
    margin: -6px !important;
}
</style>
