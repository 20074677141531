<template>
    <div>
        <div class="accordion custom-accordion mt-2" :id="`collapseExecute${node.name}`">
            <div class="accordion-item">
                <h2 class="accordion-header" style="display: flex; position: relative;">
                    <button class="accordion-button" :class="{'collapsed': !isOpen}" type="button"
                        @click="$emit('toggle-accordion')">
                        <i :class="node.icon" class="bi-icon"></i>
                        {{ node.name }}
                    </button>
                    <button class="refresh-btn" @click="refreshIdentities">
                        <i class="bi bi-arrow-clockwise"></i>
                    </button>
                </h2>
                <div :id="`collapseExecuteInner${node.name}`"
                    :class="['accordion-collapse', { 'collapse': !isOpen, 'show': isOpen }]">
                    <div class="accordion-body">

                        <ul class="list-group">
                            <li v-for="identity in filteredIdentities" :key="identity.id" class="list-group-item"
                                :draggable="true" @dragstart="dragStart($event, identity)">
                                {{ identity.email }}
                                <i class="bi bi-plus add-btn" @click="addNode(identity)"></i>
                            </li>
                            <li class="list-group-item list-group-item-action create-new-mail" @click="openModal">
                                Create New Identity
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <IdentityModalComponent :showModal="showModal" @close="closeModal" @refresh="addNewIdentity"
            style="top: 150px !important; left: 230px !important;" />
    </div>
</template>

<script>
import IdentityModalComponent from '../Identity/IdentityModalComponent.vue';

export default {
    name: 'ExecuteButtonNode',
    components: { IdentityModalComponent },
    props: ['node', 'identities', 'isOpen'],
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        filteredIdentities() {
            // Filter identities
            return this.identities.filter(identity => identity.status === "Success");
        }
    },
    methods: {
        addNode(identity) {
            const nodeData = { name: identity.email, type: 'identity' };
            this.$emit('add-node', nodeData);
        },
        dragStart(event, identity) {
            this.$emit('drag-start', event, { name: identity.email, type: 'identity' });
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        addNewIdentity(identity) {
            // Emit an event to the parent component to add the new identity
            this.$emit('add-identity', identity);
        },
        refreshIdentities() {
            this.$emit('refresh-identities');
        }
    },
};
</script>

<style scoped>
.node-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.bi-icon {
    margin-right: 10px;
}

.custom-accordion {
    width: 100%;
}

.accordion-collapse.collapse {
    display: none;
}

.accordion-collapse.show {
    display: block;
}

.accordion-item {
    margin-top: 10px;
    overflow-x: auto !important;
}

.create-new-mail {
    cursor: pointer;
    color: #007bff;
    text-align: center;
    font-weight: bold;
    background-color: aliceblue;
}

.create-new-mail:hover {
    background-color: #f8f9fa;
}

.identityModal {
    top: 125px !important;
    left: 230px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    background-color: var(--bs-accordion-active-bg);
}
</style>
