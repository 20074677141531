<template>
    <div class="modal fade show" tabindex="-1" style="display: block;" v-if="showModal" @click.self="closeModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Verify A New Email Address</h5>
                    <button @click="closeModal" type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="text-muted">
                        To verify a new email address, enter it below and click the Verify This Email Address button.
                        A verification email will be sent to the email address you entered.
                        <span class="fw-bold">Please check your spam folder if it doesn't appear in your inbox.</span>
                    </p>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-3">
                            <label for="email" class="form-label">Email Address:</label>
                        </div>
                        <div class="col-12 col-sm-9">
                            <input id="email" class="form-control" type="text" v-model="email"
                                @input="isFormValidCheck('email')" :class="{ 'is-invalid': editEmail }" required
                                name="email" />
                            <div class="invalid-feedback">Enter a valid email</div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-sm-3 d-flex align-items-center">
                            <label for="friendlyName" class="form-label mb-0">Business Name:</label>
                            <i class="fa fa-info-circle ml-2" aria-hidden="true">
                                <span class="rovlin-prod-tooltip-text">Use a business name that reflects your brand
                                    identity</span>
                            </i>
                        </div>
                        <div class="col-12 col-sm-9">
                            <input id="friendlyName" class="form-control" type="text" v-model="friendlyName"
                                @input="isFormValidCheck('friendlyName')" :class="{ 'is-invalid': editFriendlyName }"
                                required name="friendlyName" />
                            <div class="invalid-feedback">Enter a valid business name</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" :disabled="isLoading"
                        @click="addEmailForVerification">
                        <span v-if="isLoading">Loading, please wait...</span>
                        <span v-else>Verify</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";
import UTILSService from "../../../Services/utils/utils.service";

export default {
    name: "IdentityModalComponent",
    props: {
        showModal: Boolean,
    },
    data() {
        return {
            email: null,
            friendlyName: null,
            isLoading: false,
            isFormSubmitted: false,
            editEmail: null,
            editFriendlyName: null,
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        revertValues() {
            this.isFormSubmitted = false;
            this.email = null;
            this.friendlyName = null;
            this.editEmail = false;
            this.editFriendlyName = false;
        },
        isFormValidCheck(key) {
            if (!this.isFormSubmitted) return;
            if (this[key]) {
                this[key] = this[key].trim();
            }
            if (key === "email") {
                this.editEmail = !UTILSService.EMAIL_REGEX.test(this[key]);
            } else if (key === "friendlyName") {
                this.editFriendlyName = !this[key];
            }
        },
        addEmailForVerification() {
            this.isFormSubmitted = true;
            this.isFormValidCheck("email");
            this.isFormValidCheck("friendlyName");
            if (this.editEmail || this.editFriendlyName) {
                return;
            }
            this.isLoading = true;
            const body = {
                email: this.email,
                friendlyName: this.friendlyName,
            };
            this.$store.state.isLoading = true;
            APIService.addEmailForVerification(body)
                .then(
                    (response) => {
                        const newIdentity = {
                            id: response.data.id, // Assuming the response includes the new ID
                            email: this.email,
                            friendlyName: this.friendlyName,
                        };
                        this.$emit("refresh", newIdentity);
                        this.closeModal();
                        this.revertValues();
                        this.$store.state.toastMessage = "Success! A verification email has been sent.Please check your inbox to verify your email address.";
                    },
                    (error) => {
                        this.$emit("error", error);
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                    this.$store.state.isLoading = false;
                });
        },
    },
};
</script>

<style scoped>
.modal-title,
.close,
label {
    font-weight: 600;
}

.fa-info-circle .rovlin-prod-tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: rgb(40, 37, 37);
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 10%;
}

.fa-info-circle:hover .rovlin-prod-tooltip-text {
    visibility: visible;
}

@media (max-width: 575px) {
    .fa-info-circle .rovlin-prod-tooltip-text {
        bottom: 100%;
        left: 0;
    }
}
</style>
