const pricingPlanData = [
    {
        name: "Basic",
        price: "$4.99",
        unitValue: "mo*",
        annually: "paid monthly",
        chargesInfo: "*credit card will be charged monthly",
        planDesc:
            "Maximize activation, customer revenue, and retention ideal for rapidly growing businesses.",
        subscriptionPlanId: 2,
        includes: [
            "Up to 1,000 emails",
            "Advanced targeting and engagement patterns",
            "Fully customizable themes",
            "Access to all integrations",
            "24/7 Support",
        ],
    },
    {
        name: "Pro",
        price: "$19.99",
        unitValue: "mo*",
        annually: "or $200 paid annually",
        chargesInfo: "*credit card will be charged monthly",
        planDesc:
            "Onboard new users and engage customers through their lifecycle, ideal for startups and SMBs",
        subscriptionPlanId: 1,
        includes: [
            "Up to 10,000 emails",
            "Unlimited basic engagement patterns & NPS",
            "Up to 3 branded themes templates",
            "Analytics integrations",
            "A dedicated Customer Manager",
        ],
    },
    {
        name: "Pay-As-You-Go",
        price: "$0.99",
        unitValue: "100 emails",
        // annually: "one time",
        // chargesInfo: "*credit card will be charged monthly",
        planDesc:
            "Transform your product experience, Ideal for businesses with a small set of recurring customers",
        subscriptionPlanId: 3,
        includes: [
            "Credits never expire",
            "Enterprise account administration",
            "Enhanced Security & SLA",
            "Premium support and services",
        ],
    },
];

export default pricingPlanData;
