<template>
    <div class="container-fluid py-3">
        <div class="react-prod-stepper-box rounded-5">
            <div class="w-100 h-100 px-4 pt-4">
                <div class="w-100 d-flex justify-content-center flex-column">
                    <form @submit="handleOnSubmit" class="w-100 row mb-3 ms-3 ">
                        <div class="row justify-content-lg-center col-6">
                            <div class="col-12   py-4">
                                <div class="form-group row">
                                    <label for="template-name"
                                        class="col-12 col-sm-4 col-lg-3 col-form-label font-weight-bold">Template
                                        Name</label>
                                    <div class="col-12 col-sm-8 col-lg-9">
                                        <input type="text" placeholder="Your template name" v-model="tempName"
                                            @input="checkFormValidation('temp')"
                                            :class="['form-control', { 'is-invalid': this.editTempName }]"
                                            id="template-name" required>
                                        <div class="invalid-feedback">Template Name is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-lg-center col-6">
                            <div class="col-12 py-3">
                                <div class="form-group row justify-content-end">
                                    <label for="template-category"
                                        class="col-12 col-sm-4 col-lg-3 col-form-label font-weight-bold">Template
                                        Category</label>
                                    <div class="col-12 col-sm-8 col-lg-9">
                                        <input type="text" placeholder="Enter Category Name" v-model="CatName"
                                            @input="checkFormValidation('cat')"
                                            :class="['form-control', { 'is-invalid': this.editCatName }]"
                                            id="template-category" required>
                                        <div class="invalid-feedback">Template Category is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="content-align position-relative">
                        <EmailEditor v-show="paramValue == 2" :editorView="editorView" :toggleView="toggleView"
                            :viewType="viewType" ref="EmailEditor" />
                        <Editor v-show="paramValue !== 2" ref="EditorComponent" :editorView="editorView"
                            :toggleView="toggleView" :viewType="viewType" @updateContent="updateContentValue($event)" />
                    </div>
                </div>
                <div v-if="viewType === 'preview'" class="pt-1">
                    <div class="editor-preview position-relative">
                        <div class="preview-title">Preview Template</div>
                        <div class="rovlin-toolbar" style="top: -6px;" v-if="viewType === 'preview'">
                            <span class="pe-2" @click="editorView()"><i class="bi bi-x-circle-fill"
                                    aria-hidden="true"></i></span>
                        </div>
                        <div class="preview-content" ref="printMe" v-if="content && !viewContent" v-html="content">
                        </div>
                        <div class="preview-content" ref="viewPrintMe" v-if="viewContent" v-html="viewContent"></div>
                    </div>
                </div>
            </div>
            <div class="pt-4 pb-3 pe-2 d-flex justify-content-end rovlin-prod-content-align">
                <div class="pt-2 me-2" style="cursor:pointer" @click="backNavigate">
                    Go Back
                </div>
                <div class=" btn rounded-4 btn-primary me-2">Send Template</div>
                <div class=" btn rounded-4 btn-primary" @click="handleOnSubmit">{{ actionType }} Template</div>
            </div>
        </div>
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";
import ImageHandlerService from "../../../Services/imageHandler/image-handler.service"
import Editor from "./Editor.vue";
import EmailEditor from "./EmailEditor.vue"
export default {
    name: "TemplateEditor",
    components: {
        Editor, EmailEditor
    },
    data() {
        return {
            paramValue: null,
            viewType: 'editor',
            templateModal: true,
            content: "",
            viewContent: "",
            editTempName: null,
            editCatName: null,
            tempName: this.$store.state.TemplateName,
            CatName: this.$store.state.TemplateCategory,
            showModal: false,
            activeTab: "existing",
            isFormSubmitted: false,
        };
    },
    methods: {
        updateContentValue(value) {
            this.content = value
            console.log(" => this.content: ", value);
        },
        checkFormValidation(key) {

            switch (key) {
                case "temp":
                    this.editTempName = !this.tempName
                    break;

                case "cat":
                    this.editCatName = !this.CatName
                    break;

                default:
                    break;
            }
        },
        FinalFormValidation() {
            if (!this.tempName || !this.CatName) {
                const newArr = ['temp', 'cat'];
                for (let index = 0; index < newArr.length; index++) {
                    this.checkFormValidation(newArr[index]);
                }
                return false
            }
            else {
                return true;
            }
        },
        CloseTemplateModal() {
            this.templateModal = false
        },
        backNavigate() {
            this.$router.push({ name: "createTemplate" });
            this.$store.state.TemplateName = '';

        },

        getHeight() {
            return this.$refs?.printMe?.offsetHeight || [[]];
        },

        saveValue() {
            this.$store.state.toastMessage = "Configuring Template";
            this.toggleView();
            setTimeout(async() => { this.saveContent() }, 1000)
        },
        async saveContent() {
            if (!this.tempName || !this.CatName) {
                return false;
            }
            const body = {
                data: this.content,
                name: this.tempName,
            };
            if (!body.name) {
                return false;
            }
            this.$store.state.toastMessage = "Creating Template ";
            this.$store.state.isLoading = true;
            this.viewContent = JSON.parse(JSON.stringify(this.content));
            const printEle = this.$refs.printMe;
            const options = {
                type: "dataURL",
                scale: 400 / printEle?.scrollWidth
            };
            await this.getUpdatedContent();
            const el = this.$refs.viewPrintMe;

            let status;
            body["thumbnail"] = await this.$html2canvas(el, options);
            if (!this.templateId) {
                let preAssignedData;
                preAssignedData = await this.gePreAssignedUrl(body.name, null);
                const fileName = body.name + ".png";
                const file = this.dataURLtoFile(body["thumbnail"], fileName);
                status = await this.uploadCustomDocument(
                    preAssignedData.data.thumbnailFields,
                    file,
                    preAssignedData.data.objectUrl
                );
                // }
                status = await this.uploadCustomDocument(
                    preAssignedData.data.objectFields,
                    this.content,
                    preAssignedData.data.thumbnailUrl
                );
                body["template_key"] = preAssignedData && preAssignedData.data && preAssignedData.data.objectFields && preAssignedData.data.objectFields.key;
                body["post_status"] = status;
                body["file_secret"] = preAssignedData && preAssignedData.data && preAssignedData.data.file_secret;
                body["tag_name"] = this.CatName;
                delete body.data;
                delete body.thumbnail;
                APIService.saveCampaignTemplate(body)
                    .then(() => {
                        this.$store.state.toastMessage = "Template created successfully";
                    })
                    .catch(() => {
                        this.$store.state.toastMessage = "Template name already exist";
                    })
                    .finally(() => {
                        this.$store.state.toastMessage = "";
                        this.$store.state.isLoading = false;
                        this.navigateTo();
                    });
            } else {
                let preAssignedData;
                let status;

                preAssignedData = await this.gePutPreAssignedUrl(
                    this.templateId,
                    null
                );
                const fileName = body.name + ".png";
                const file = this.dataURLtoFile(body["thumbnail"], fileName);
                status = await this.uploadCustomDocument(
                    preAssignedData.data.thumbnailFields,
                    file,
                    preAssignedData.data.objectUrl
                );
                // }
                status = await this.uploadCustomDocument(
                    preAssignedData.data.objectFields,
                    this.content,
                    preAssignedData.data.thumbnailUrl
                );
                body["template_key"] = preAssignedData && preAssignedData.data && preAssignedData.data.objectFields && preAssignedData.data.objectFields.key;
                body["post_status"] = status;
                body["file_secret"] = preAssignedData && preAssignedData.data && preAssignedData.data.file_secret;
                body["template_id"] = this.templateId;
                body["new_name"] = this.tempName;
                body["tag_name"] = this.CatName;
                delete body.name;
                delete body.data;
                delete body.thumbnail;
                APIService.updateCampaignTemplate(body)
                    .then(() => {
                        this.$store.state.toastMessage = "Template updated successfully";
                        this.navigateTo();
                    })
                    .catch(() => {
                        this.$store.state.toastMessage = "Template name already exist";
                    })
                    .finally(() => {
                        this.$store.state.toastMessage = "";
                        this.$store.state.isLoading = false;
                    });
            }
        },

        getAllUrls(text) {
            const urlRegex = /(https?:\/\/[^\s]+(\s?\w+)+(.(?:png|jpg|jpeg))+)/g;
            const allUrls = text.match(urlRegex);
            return allUrls;
        },

        async getUpdatedContent() {
            const allUrl = this.getAllUrls(this.content);
            const downloadedImages = await ImageHandlerService.downloadUrls(allUrl);
            for (const view of downloadedImages) {
                this.viewContent = this.viewContent.replaceAll(view.url, view.base64);
            }
        },
        navigateTo() {
            this.$router.push({ name: "createTemplate" });
        },

        getTemplateById(bucketId) {
            if (!bucketId) {
                return false;
            }
            this.$store.state.isLoading = true;
            APIService.getCampaignTemplate(bucketId)
                .then((res) => {
                    this.tempName = res.data.name;
                    this.CatName = res.data.tag;
                    if (res?.data?.data) this.getTemplateByUrl(res.data.data);
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },

        getTemplateByUrl(url) {
            APIService.getUrlInfo(url).then((template) => {
                this.content = template.data;
            });
        },

        getTemplateFromUrl(url) {
            return new Promise((resolve, reject) => {
                try {
                    APIService.getUrlInfo(url).then((template) => {
                        resolve(template);
                    });
                } catch (e) {
                    reject(e);
                }
            });
        },

        getAllTemplateUrl(bucketId) {
            return new Promise((resolve, reject) => {
                try {
                    APIService.getCampaignTemplate(bucketId).then((res) => {
                        resolve(res.data.data);
                    });
                } catch (e) {
                    reject(e);
                }
            });
        },

        async getTemplateWithUrl(ids) {
            this.$store.state.isLoading = true;
            const urls = await Promise.all(
                ids.map((id) => this.getAllTemplateUrl(id))
            );
            const responses = await Promise.all(
                urls.map((url) => this.getTemplateFromUrl(url))
            );
            var content = "";
            responses.forEach((response) => {
                content += response.data + "<br/>";
            });
            this.$store.state.isLoading = false;
            this.content = content;
        },

        async handleOnSubmit(e) {
            const formCheck = this.FinalFormValidation()
            console.log(" => formCheck : ", formCheck);
            if (!formCheck) {
                return
            }
            if (this.paramValue == 2) {
                const contentValue = await this.$refs.EmailEditor.exportHtml();
                console.log(" => content value : ", contentValue);
                this.content = contentValue
            }
            else {
                this.content = this.$refs?.EditorComponent?.getContent();
            }
            this.isFormSubmitted = true;
            if ((!this.tempName && !this.CatName) || !this.content.trim()) return false;
            e.preventDefault();
            this.saveValue();
        },

        gePreAssignedUrl(name, thumb_url) {
            return new Promise((resolve, reject) => {
                try {
                    APIService.getPreAssignedUrl(name, thumb_url).then((res) => {
                        resolve(res);
                    });
                } catch (error) {
                    reject(error);
                }
            });
        },

        gePutPreAssignedUrl(name, thumb_url) {
            return new Promise((resolve, reject) => {
                try {
                    APIService.gePutPreAssignedUrl(name, thumb_url).then((res) => {
                        resolve(res);
                    });
                } catch (error) {
                    reject(error);
                }
            });
        },

        uploadCustomDocument(objectFields, file, url) {
            return new Promise((resolve, reject) => {
                try {
                    let formData = new FormData();
                    formData.append("key", objectFields["key"]);
                    formData.append("AWSAccessKeyId", objectFields["AWSAccessKeyId"]);
                    formData.append(
                        "x-amz-security-token",
                        objectFields["x-amz-security-token"]
                    );
                    formData.append("policy", objectFields["policy"]);
                    formData.append("signature", objectFields["signature"]);
                    formData.append("file", file);
                    APIService.uploadCustomDocument(url, formData).then((res) => {
                        resolve(res.status);
                    });
                } catch (err) {
                    reject(false);
                }
            });
        },

        dataURLtoFile(dataUrl, filename) {
            var arr = dataUrl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },

        async toggleView() {
            this.viewType = 'preview';
            if (this.paramValue != 2) {
                this.content = this.$refs.EditorComponent.content;
            }
            else {
                const contentValue = await this.$refs.EmailEditor.exportHtml();
                this.content = contentValue
            }
        },

        editorView() {
            this.viewType = 'editor';
        }
    },

    async created() {
        const params = this.$route.params;
        const queryParams = this.$route.query;
        console.log(" => query params : ", queryParams);
        this.actionType = "Create";
        if (params.templateId === "new" && queryParams.content) {
            const fileResponse = await this.getTemplateFromUrl(queryParams.content);
            this.content = fileResponse.data;
            return;
        }
        if (queryParams && queryParams.templateIds) {
            this.getTemplateWithUrl(queryParams.templateIds);
        }
        if (params && params.templateId && params.templateId !== "new" && !isNaN(Number(params.templateId))) {
            this.templateId = params.templateId;
            this.actionType = "Update";
            this.getTemplateById(params.templateId);
        } else if (params.templateId !== 'new') {
            this.content = params.templateId;
        }
    },
    mounted() {
        if (this.$route.query.type == 'draganddrop') {
            this.paramValue = 2
        }
        else {
            this.paramValue = 1
        }
        console.log(" => this.paramValue: ", this.paramValue);
    }
};
</script>

<style>

.react-prod-stepper-box {
  background-color: white;
  min-height: 200px;
}

.modal-tabs {
    background: gainsboro;
    font-weight: 600;
    cursor: pointer;
}

.active-tab {
    background-color: #dd6b10 !important;
    color: white;
}

.dropbox {
    outline: 2px dashed grey;
    /* the dash box */
    outline-offset: -10px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    /* minimum height */
    position: relative;
    cursor: pointer;
}

.input-file {
    opacity: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    background: lightblue;
    /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

.custom-input {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-input:not(:hover):not(:focus) {
    border-color: transparent;
}

.image-selected {
    border-color: #dd6b10 !important;
}

.rovlin-toolbar {
    position: absolute;
    right: 0;
    font-size: 20px;
    color: #4a4a4a;
    height: 47px;
    display: inline-flex;
    align-items: center;
}

.rovlin-toolbar i {
    cursor: pointer;
}

.editor-preview {
    border: 1px solid lightgray;
    border-radius: 7px;
}

.preview-content {
    margin-top: 45px;
    border-top: 1px solid lightgray;
    padding: 15px;
    overflow: scroll;
}

.preview-title {
    position: absolute;
    display: flex;
    align-items: center;
    height: 47px;
    padding: 0 20px;
    font-size: 22px;
    font-weight: 600;
}

.content-block {
    visibility: hidden;
    position: absolute;
}

.upload-button {
    background-color: #ed8b3c !important;
    border-color: #ed8b3c !important;
}

:deep(.cke_contents) {
    height: calc(100vh - 480px) !important;
}
</style>
