<template>
    <div class="campaign-node" @contextmenu.prevent @click.stop>
        <b>Campaign Name: </b><span>{{ name }}</span><br />
        <template v-if="!hideSubject">
            <b>Subject: </b><span>{{ subject }}</span><br />
        </template>
        <b>Date: </b>{{ startDate }} - {{ endDate }}
        <button v-if="isEditable" class="edit-button" @click.stop="handleEditNode">
            <i class="bi bi-pencil-fill rovlin-prod-icons_info rovlin-prod-cursor-pointer" aria-hidden="true"></i>
        </button>
    </div>
</template>

<script>
import { eventBus } from '@/eventBus';

export default {
    name: 'CampaignNodeContent',
    props: {
        name: String,
        subject: String,
        startDate: String,
        endDate: String,
        nodeId: Number,
        isEditable: {
            type: Boolean,
            default: true
        },
        hideSubject: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleEditNode() {
            // console.log("Edit button clicked in CampaignNodeContent for node:", this.nodeId);
            const nodeData = {
                id: this.nodeId,
                name: this.name,
                subject: this.subject,
                startDate: this.startDate,
                endDate: this.endDate,
                type: 'campaign'
            };
            eventBus.emit('campaign-node-edit', nodeData);
        }
    }
};
</script>

<style scoped>
.campaign-node {
    position: relative;
    max-width: 300px;
    word-wrap: break-word;
    white-space: normal;
    width: 100%;
}

.edit-button {
    position: absolute;
    height: 30px;
    width: 30px;
    color: white;
    font-weight: 700;
    text-align: center;
    font-family: monospace;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid white !important;
    background-color: #0d6efd;
    z-index: 4;
    right: 4px;
    top: -55px;
    display: block !important;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.parent-node:hover .edit-button {
    display: block !important;
    opacity: 1;
}

b {
    color: darkslateblue !important;
    font-weight: normal !important;
}
</style>
