<template>
    <button class=" btn rounded-4 btn-primary mr-2 mb-3" data-bs-toggle="modal" data-bs-target="#PlansModal" id="plans-button">
        {{ currentPlanInfo ? 'Update Plan' : 'Subscribe Plan' }}
    </button>

    <div class="modal fade" id="PlansModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Our Plans</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeModal" @close="handleClose"
                        aria-label="Close"></button>

                </div>
                <div class="modal-body">
                    <div id="pricing" class="container-fluid rovlin-prod-card">
                        <div class="row ">
                            <div class="col-sm-4 col-xs-12 rovlin-prod-plan-block" v-for="(item, index) in plansList" :key="item.name"
                                v-bind:class="{
                                    rovlin_card: index === 1,
                                    best_plan: index === 1,
                                    active_plan:
                                        currentPlanInfo &&
                                        item.subscriptionPlanId ===
                                        currentPlanInfo.subscriptionPlanId,
                                }" style="position: relative">
                                <div class="rovlin-prod-top-border"></div>
                                <div class="panel panel-default text-center py-3 custom-plans h-100"
                                    style="padding-bottom: 60px !important;">
                                    <div style="height: 121px; display: inline-flex;align-items: center;">
                                        <div>
                                            <div class="rovlin-prod-panel-heading">
                                                <h5>{{ item.name }}</h5>
                                            </div>
                                            <div>Starting at</div>
                                            <h3 style="font-size: 25px">
                                                {{ item.price }}/{{ item.unitValue }}
                                            </h3>
                                            <sub>{{ item.annually }}</sub>
                                            <div>
                                                <sub class="mt-2">{{ item.chargesInfo }}</sub>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <sub>{{ item.planDesc }}</sub>
                                    </div>
                                    <div class="py-2" style="font-weight: 700; font-size: 12px">
                                        Includes:
                                    </div>
                                    <ul class="mx-4">
                                        <li v-for="include in item.includes" :key="include">
                                            {{ include }}
                                        </li>
                                    </ul>
                                    <div style="
                                        display: inline-flex;
                                        align-items: center;
                                        position: absolute;
                                        width: 100%;
                                        justify-content: center;
                                        bottom: 20px;
                                        left: 0;">
                                        <button class=" btn rounded-4 btn-primary mt-3" @click="subscribePlan(item)" :disabled="currentPlanInfo &&
                                            item.subscriptionPlanId ===
                                            currentPlanInfo.subscriptionPlanId
                                            ">
                                            Check it Out
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";
import PricingPlanData from "./pricing-plan.data";
export default {
    name: "PlansComponent",
    props: {
        showModal: Boolean,
        currentPlanInfo: Object,
    },
    data() {
        return {
            plansList: PricingPlanData,
        };
    },
    created() {
        this.getAllPlans();
    },
    watch: {
        showModal(val) {
            console.log(" => showModal: ", val);
        },
    },
    methods: {
        handleClose() {
            this.$emit("handleClose", true);
        },
        subscribePlan(plan) {
            this.$refs?.closeModal.click();
            this.$emit("subscribePlan", plan);
        },
        filteredResponse(arr) {
            const newArr = arr.sort((a, b) => {
                if (a.code === "basic") {
                    return -1;
                } else if (b.code === "basic") {
                    return 1;
                } else if (a.code === "pro") {
                    return -1;
                } else if (b.code === "pro") {
                    return 1;
                } else {
                    return 0;
                }
            });
            return newArr
        },
        getAllPlans() {
            console.log(" => it runs: ",);
            APIService.getPlans().then((res) => {
                console.log(" => data : ", res?.data);
                const newResponse = this.filteredResponse(res?.data);
                this.plansList.filter((plan, i) => {
                    if (!newResponse || !newResponse[i]) return;
                    plan.price = '$' + newResponse[i].price;
                    plan.planCode = newResponse[i].code
                    plan.subscriptionPlanId = newResponse[i].subscriptionId;
                    plan.unitValue = newResponse[i].emailsOffer;
                });
            }).catch((err) => {
                console.log(" => error : ", err);
            })
        },

        checkCurrentPlanInfo() {
            console.log(" => currentPlanInfo: ", this.currentPlanInfo);
        },
    },
};
</script>

<style scoped>
.rovlin-prod-panel-heading {
    text-transform: uppercase;
}

.rovlin-prod-panel-heading h5 {
    font-weight: 600;
}

li {
    list-style-type: disc;
    text-align: left;
    font-size: 12px;
    line-height: 1.5;
}

.rovlin-prod-card {
    box-shadow: 0 0.46875rem 2.1875rem rgb(90 97 105 / 10%),
        0 0.9375rem 1.40625rem rgb(90 97 105 / 10%),
        0 0.25rem 0.53125rem rgb(90 97 105 / 12%),
        0 0.125rem 0.1875rem rgb(90 97 105 / 10%);
    border-radius: 15px;
}

.rovlin-prod-top-border {
    height: 15px;
    background: #00a1ff;
    width: calc(100% + 24px);
    margin: 0 -12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.best_plan {
    margin-top: -15px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.rovlin-prod-plan-block:first-child .rovlin-prod-top-border {
    border-top-right-radius: 0px;
}

.rovlin-prod-plan-block:last-child .rovlin-prod-top-border {
    border-top-left-radius: 0px;
}

.trash-main-block {
    height: calc(100vh - 210px);
}

.active_plan {
    outline: 2px solid lightgreen;
    border-radius: 10px;
}

.disabled-button {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}
</style>
