<template>
    <span class="bi" :style="newStyle" :class="icon" data-bs-toggle="tooltip" :title="content" :data-bs-placement="placement"></span>
</template>
  
<script>
import { Tooltip } from 'bootstrap';

export default {
    name: "Tooltip-vue",
    props: ['content', 'icon', 'newStyle', 'placement'],
    mounted() {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }
};
</script>