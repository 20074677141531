<template>
    <button class=" btn rounded-4 btn-primary" id="create-template" @click="handleClick(2);handleModalChange()">
        Create New Template
    </button>
   
    <QueryModal :addAiTemplate="addAiTemplate" v-if="isDev" templateIcon="false"/>
    <!-- <button class=" btn rounded-4 btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="create-template">
        Create New Template
    </button> -->
    <!-- <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Choose Options</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @close="handleClose"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="rovlin-prod-template-modal d-flex ml-0 mr-0">
                        <div :class="selectedTemplate == 1 ? `rovlin-prod-template-box` : `rovlin-prod-template-box-not`" @click="handleClick(1)">
                            <div :class="selectedTemplate == 1 ? `rovlin-prod-template-text` : `rovlin-prod-template-text-not`">Drag and Drop Editor
                            </div>
                        </div>
                        <div :class="selectedTemplate == 2 ? `rovlin-prod-template-box` : `rovlin-prod-template-box-not`" @click="handleClick(2)">
                            <div :class="selectedTemplate == 2 ? `rovlin-prod-template-text` : `rovlin-prod-template-text-not`">Custom Editor</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="handleModalChange()" data-bs-dismiss="modal" class=" btn rounded-4 btn-primary">Save
                        changes</button>
                </div>
            </div>
        </div>
    </div> -->

    <!-- conditional Modal Ends-->
</template>

<script>
import QueryModal from "./ai/QueryModal.vue"
export default {
    name: "TemplateModal",
    props: ['handleClose', 'saveModalChanges', 'ShowTemplateModal'],
    components:{QueryModal},
    data() {
        return {
            selectedTemplate: null,
            queryModal:false
        }
    },
    methods: {
        handleClick(value) {
            this.selectedTemplate = value;
        },
        handleModalChange() {
            this.saveModalChanges(this.selectedTemplate);
        },
        addAiTemplate(query){
            console.log("query",query)
            // this.handleClick(2)
            // this.saveModalChanges(this.selectedTemplate);
        }
    },
    computed:{
        isDev() {
            return process.env.VUE_APP_Environment === "dev";
        },
    }
}
</script>

<style>
.rovlin-prod-template-modal {
    width: 100%;
    height: 100px;
    column-gap: 15px;
}

/** UnSelected Class */
.rovlin-prod-template-box-not {
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    background: #F4F7FD;
}

.rovlin-prod-template-text-not {
    width: 100%;
    height: 100%;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 114%;
}

/** Selected Class**/
.rovlin-prod-template-box {
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    width: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid #5a4fdc;
    background: #F4F7FD;
}

.template-text {
    font-size: 114%;
    width: 100%;
    height: 100%;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5a4fdc;
}</style>