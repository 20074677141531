<template>
    <div class="container-fluid main-content-container pb-4">
        <div class="row rovlin-prod-page-header py-4 rovlin-prod-content-align">
            <h3 class="rovlin-prod-page-title">
                <!-- Conditionally render the back arrow based on the isModal prop -->
                <i v-if="!isModal" class="bi bi-arrow-left cursor-pointer" @click="navigateTo"
                    aria-hidden="true"></i>&nbsp;
                <span>{{ leadsId ? "Update" : "Create New " }} Leads</span>
            </h3>
        </div>

        <form @submit="onSubmit" @reset="onReset"
            class="col-12 row p-0 m-0 rovlin-prod-content-align custom-new-card p-3">
            <div class="col-6">
                <div class="mb-3">
                    <label for="group-name" class="form-label">Group Name</label>
                    <input type="text" class="form-control" :disabled="!isNewLeadGroup"
                        @input="isFormValidCheck('groupName')" @blur="isFormValidCheck('groupName')" id="group-name"
                        v-model="groupName" :class="{ 'is-invalid': editGroupName }" required
                        placeholder="Enter group name" />
                    <div class="invalid-feedback">Group Name is required</div>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <label for="customer-first-name" class="form-label">First Name</label>
                    <input type="text" class="form-control" id="customer-first-name" v-model="firstName"
                        @blur="isFormValidCheck('firstName')" @input="isFormValidCheck('firstName')"
                        :class="{ 'is-invalid': editFirstName }" required placeholder="Enter First name" />
                    <div class="invalid-feedback">Customer First Name is required</div>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <label for="customer-second-name" class="form-label">Last Name</label>
                    <input type="text" class="form-control" id="customer-second-name" v-model="lastName"
                        @blur="isFormValidCheck('lastName')" @input="isFormValidCheck('lastName')"
                        :class="{ 'is-invalid': editLastName }" required placeholder="Enter Last Name" />
                    <div class="invalid-feedback">Customer Last Name is required</div>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <label for="customer-email" class="form-label">Email</label>
                    <input type="email" class="form-control" id="customer-email" v-model="email"
                        @blur="isFormValidCheck('email')" @input="isFormValidCheck('email')"
                        :class="{ 'is-invalid': editEmail }" required placeholder="Enter customer email" />
                    <div class="invalid-feedback">Enter valid email</div>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-3">
                    <label for="customer-mobile" class="form-label">Mobile No</label>
                    <input type="text" class="form-control" id="customer-mobile" v-model="phoneNumber"
                        @input="acceptNumber('phoneNumber')" maxlength="14" placeholder="Enter customer Mobile" />
                </div>
            </div>

            <div class="col-6">
                <div class="mb-3">
                    <label for="customer-whatsapp" class="form-label">Whatsapp Number</label>
                    <input type="text" class="form-control" id="customer-whatsapp" v-model="whatsappNumber"
                        @input="acceptNumber('whatsappNumber')" maxlength="14"
                        placeholder="Enter Customer Whatsapp Number" />
                </div>
            </div>

            <div class="col-6">
                <div class="mb-3">
                    <label for="customer-messenger" class="form-label">Messenger Id</label>
                    <input type="text" class="form-control" id="customer-messenger" v-model="messengerId"
                        placeholder="Enter Customer Messenger Id" />
                </div>
            </div>
            <div class="row align-items-center py-2">
                <div class="col-auto pe-0">
                    <button class="btn rounded-4 btn-danger pe-0 rounded-4" @click="navigateTo">Cancel</button>
                </div>
                <div class="col-auto">
                    <button class="btn rounded-4 btn-primary rounded-4" @click="onSubmit">{{ leadsId ? "Update" : "Save & Add New " }} Lead</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { useRoute } from 'vue-router';
import APIService from '../../../Services/ApiService/apiService';
import UTILSService from '../../../Services/utils/utils.service';

export default {
    props: {
        isModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            validateData: ['groupName', 'firstName', 'lastName', 'email'],
            groupName: null,
            groupId: null,
            name: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            whatsappNumber: null,
            messengerId: null,
            email: null,
            actionType: null,
            leadsId: null,
            isFormSubmitted: false,
            groupNameParams: null,
            // validation
            editGroupName: null,
            editFirstName: null,
            editLastName: null,
            editEmail: null,
            isNewLeadGroup: false,
        };
    },
    methods: {
        onSubmit(evt) {
            this.isFormSubmitted = true;
            const returnValue = this.checkValidations();
            if (returnValue == false) {
                return false;
            }
            evt.preventDefault();
            this.create();
        },
        checkValidations() {
            this.validateData.map((item) => {
                this.isFormValidCheck(item);
            });
            if (!this.editFirstName && !this.editEmail && !this.editLastName && !this.editGroupName) {
                return true;
            } else {
                return false;
            }
        },
        create() {
            const data = [
                {
                    groupName: this.groupName,
                    groupId: this.groupId,
                    customers: [
                        {
                            firstName: this.firstName,
                            lastName: this.lastName,
                            whatsappNumber: this.whatsappNumber,
                            messengerId: this.messengerId,
                            email: this.email,
                            phoneNumber: this.phoneNumber,
                        },
                    ],
                },
            ];
            if (this.groupId === this.groupNameParams) {
                delete data[0].groupId;
            }
            this.$store.state.isLoading = true;

            if (!this.leadsId) {
                APIService.createCustomers(data)
                    .then(() => {
                        this.$store.state.toastMessage = 'Created successfully!';
                        this.resetForm();
                        if (this.isModal) {
                            this.$emit('close'); // Emit close event to parent
                            this.$emit('leadGroupCreated');
                        }
                    })
                    .catch((error) => {
                        this.$store.state.toastMessage = error;
                    })
                    .finally(() => {
                        this.$store.state.toastMessage = '';
                        this.$store.state.isLoading = false;
                    });
            } else {
                data.id = this.leadsId;
                const updateData = {
                    id: this.leadsId,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    whatsappNumber: this.whatsappNumber,
                    messengerId: this.messengerId,
                    email: this.email,
                    phoneNumber: this.phoneNumber,
                    groupId: this.groupId,
                };
                APIService.updateCustomers(updateData)
                    .then(() => {
                        this.navigateTo();
                        this.$store.state.toastMessage = 'Updated successfully!';
                        if (this.isModal) {
                            this.$emit('close'); // Emit close event to parent
                        }
                    })
                    .catch((error) => {
                        this.$store.state.toastMessage = error;
                    })
                    .finally(() => {
                        this.$store.state.toastMessage = '';
                        this.$store.state.isLoading = false;
                    });
            }
        },
        onReset(evt) {
            evt.preventDefault();
            this.resetForm();
        },
        navigateTo() {
            if (this.isModal) {
                // If used in a modal, don't navigate
                this.$emit('close');
                return;
            }

            let currentPage = 1;

            const route = this.$route;
            if (route.query && route.query.page) {
                currentPage = Number(route.query.page);
            }
            this.$router.push({ name: 'leads', params: { id: this.leadsGroupsId }, query: { page: currentPage } });
        },

        getLeadsById(id) {
            this.$store.state.isLoading = true;
            APIService.getCustomerById(id)
                .then((res) => {
                    this.$store.state.isLoading = false;
                    const leadsData = res.data[0];
                    this.groupName = leadsData.group.name || '';
                    this.firstName = leadsData.firstName;
                    this.lastName = leadsData.lastName;
                    this.whatsappNumber = leadsData.whatsappNumber;
                    this.messengerId = leadsData.messengerId;
                    this.name = leadsData.name || '';
                    this.phoneNumber = leadsData.phoneNumber;
                    this.email = leadsData.email;
                    this.leadsId = leadsData.id;
                })
                .catch(() => {
                    this.$store.state.toastMessage = 'Something went wrong!';
                })
                .finally(() => {
                    this.$store.state.toastMessage = '';
                    this.$store.state.isLoading = false;
                });
        },
        acceptNumber(key) {
            let x = this[key].replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            const data = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            this[key] = data;
        },
        isFormValidCheck(key) {
            console.log(' => change run s: ', key);
            switch (key) {
                case 'groupName':
                    this.editGroupName = !this.groupName;
                    break;

                case 'firstName':
                    this.editFirstName = !this.firstName;
                    break;

                case 'lastName':
                    this.editLastName = !this.lastName;
                    break;

                case 'email':
                    this.editEmail = !UTILSService.EMAIL_REGEX.test(this.email);
                    break;

                default:
                    break;
            }
        },
        resetForm() {
            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.groupName = '';
            this.whatsappNumber = '';
            this.phoneNumber = '';
            this.messengerId = '';
            this.isFormSubmitted = false;
        },
    },
    created() {
        const route = useRoute();
        console.log('const route = useRoute();', route.query);
        const params = this.$route.params;
        this.actionType = 'Create';
        if (Object.keys(params).length === 0) {
            this.isNewLeadGroup = true;
        }
        if (params.group && params.id === 'new') {
            this.groupName = params.groupName;
        }
        if (params.group && params.group !== 'new') {
            this.groupId = params.group;
            this.groupNameParams = params.groupName;
        }
        if (params && params.id && params.id !== 'new') {
            this.actionType = 'Update';
            this.getLeadsById(params.id);
        }
    },
};
</script>

<style scoped>
.rovlin-prod-content-align {
    margin: 0 15px;
}

.fa {
    cursor: pointer;
}
</style>
