<template>
    <ul class="dropdown-menu dropdown-menu-lg-end p-0 border-0" :class="[{ 'show': this.$store.state.showProfile }]"
        aria-labelledby="userMenu" style="right:15px;min-width: 50px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;">

        <router-link :to="item.link" @click="handleClick()" v-for="(item, index) in dropdownData" style="padding:4px 10px"
            class="dropdown-item drop-item d-flex py-2" :key="index">
            <i :class="item.icon" class="op-9" style="font-size: 14px;"></i>
            <span class="ms-3">{{ item.name }}</span>
        </router-link>


        <li @click="signOutClick()" class="d-flex dropdown-item drop-item py-2" style="padding:4px 10px">
            <i class="bi bi-box-arrow-left"></i>
            <span class="ms-3" type="button">Sign Out</span>
        </li>
    </ul>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
    name: "DropdownModal",
    data() {
        return {
            dropdownData: [
                {
                    name: "Setting",
                    icon: "bi bi-sliders",
                    link: "/user-profile"
                },
                {
                    name: "Billing",
                    icon: "bi bi-cash-stack",
                    link: "/billing"
                },
                {
                    name: "Transactions",
                    icon: "bi bi-receipt",
                    link: "/transaction-history"
                },
                {
                    name: "Developer Setting",
                    icon: "bi bi-code-slash",
                    link: "/developer-setting"
                }
            ]
        }
    },
    methods: {
        handleClick() {
            if (this.$store.state.showProfile == true) this.$store.state.showProfile = false
        },
        signOutClick() {
            if (this.$store.state.showProfile == true) this.$store.state.showProfile = false
            this.signOut()
        },
        signOut() {
            Auth.signOut().then(() => {
                window.location.href = "https://rovlin.com";
            });
        },
    },
}
</script>

<style>
.dropdown-item.active,
.dropdown-item:active {
    color: #5a4fdc !important;
   background-color: #845ADF0D !important;
}


.drop-item{
    color: #333335 !important;
    font-size: .8125rem !important;
    font-weight: 500 !important;
} 

.drop-item:hover{
    color: #5a4fdc !important;
   background-color: #845ADF0D !important;
}
</style>
