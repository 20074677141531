export const integrationConfig = [
    {
        title:"SalesForce",
        fields:[
            {
                label:"Client Id",
                value:"",
            },
            {
                label:"Client Secret",
                value:"",
            }
        ],
    },
    {
        title:"MailChimp",
        fields:[
            {
                label:"Connection Name",
                value:"",
            },
            {
                label:"Account Id",
                value:"",
            },
            {
                label:"Account Secret",
                value:"",
            },
        ],
    }
]