<template>
    <div class="container-fluid main-content-container px-4 pb-4">
        <div class="row g-0 rovlin-prod-page-header py-4">
            <h3 class="rovlin-prod-page-title">Your Profile</h3>
        </div>
        <div class="p-0 rovlin-prod-user-profile-container" style="box-shadow: none !important">
            <form @submit="updateUserProfile" class="row custom-new-card p-3">
                <div class="mb-3 col-lg-6 col-sm-12">
                    <label for="name" class="form-label">Your Name:</label>
                    <input id="name" class="form-control" v-model="form.name" @blur="validate('name')"
                        placeholder="Enter Your Name" @input="validate('name')" :class="{ 'is-invalid': this.formError.name != '' }" />
                    <div class="invalid-feedback" id="input-live-feedback">
                        {{ this.formError.name }}
                    </div>
                </div>

                <div class="mb-3 col-lg-6 col-sm-12">
                    <label for="input-1" class="form-label">Email address:</label>
                    <input id="input-1" disabled class="form-control" v-model="form.email" @blur="validate('email')"
                        @input="validate('email')" type="email" placeholder="Enter email" />
                    <div class="invalid-feedback" id="input-live-feedback" :state="false">
                        {{ this.formError.email }}
                    </div>
                </div>

                <div class="mb-3 col-lg-6 col-sm-12">
                    <label for="input-phone-number" class="form-label">Phone number:</label
                    >
                    <input id="input-phone-number" class="form-control" v-model="form.phone" @blur="validate('phoneNo')"
                        @input="validate('phoneNo')" type="tel" placeholder="Phone no. Required" :class="{ 'is-invalid': this.formError.phone != '' }" />
                    <div class="invalid-feedback" id="input-live-feedback" :state="false">
                        {{ this.formError.phone }}
                    </div>
                </div>

                <div class="mb-3 col-lg-6 col-sm-12">
                    <label for="input-organisation" class="form-label">Organisation:</label>
                    <input id="input-organisation" class="form-control" v-model="form.organization"
                        @blur="validate('organization')" @input="validate('organization')"
                        placeholder="Enter Organization" :class="{ 'is-invalid': this.formError.organization != '' }" />
                    <div class="invalid-feedback" id="input-live-feedback" :state="false">
                        {{ this.formError.organization }}
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-start pb-3">
                    <button type="submit" class=" btn rounded-4 btn-primary mr-3">
                        Update Profile
                    </button>
                </div>
            </form>
            <form @submit="onSubmit" @reset="onReset" class="row custom-new-card p-3 mt-4">
                <div class="mb-3 col-12">
                    <label class="form-label" >Edit Footer</label>
                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <Editor :content="content" ref="newEditor" @updateContent="updateContentValue($event)"
                                keyValue="footer" />
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <div class="preview-container">
                                <div class="content-title">
                                    Preview Template
                                </div>
                                <div class="" style="padding: 5px 21px" v-html="content"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-start">
                    <button type="submit" class=" btn rounded-4 btn-primary mr-3">
                        Update Footer
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import apiService from "../../../Services/ApiService/apiService";
import CustomEditor from "@/components/shared/CustomEditor/CustomEditor.vue";

export default {
    name: "UserProfileComponent",
    components: {
        Editor: CustomEditor
    },
    data() {
        return {
            form: {
                email: "",
                organization: "",
                name: "",
                phone: null,
            },
            formError: {
                email: "",
                organization: "",
                name: "",
                phone: "",
            },
            foods: [
                { text: "Select One", value: null },
                "Carrots",
                "Beans",
                "Tomatoes",
                "Corn",
            ],
            selected: [], // Must be an array reference!
            social: "",
            custom: "",
            address: "",
            unsubscribe: "",
            url: "",
            logo: "",
            modalShow: false.valueOf,
            content: "",
        };
    },
    methods: {
        updateContentValue(value) {
            this.content = value
        },
        async onSubmit(evt) {
            evt.preventDefault();
            if (this.content) {
                console.log("allValid");
                let preData;
                let status;
                this.$store.state.toastMessage = "Creating Footer Template";
                preData = await this.getPreAssignedFooterUrl();
                status = await this.uploadFooterDetails(
                    preData.objectFields,
                    this.content,
                    preData.objectUrl
                );
                console.log("post api status", status);
                this.$store.state.toastMessage = "";
            }
        },

        getPreAssignedFooterUrl() {
            return new Promise((resolve, reject) => {
                try {
                    apiService.getFooterPresignedUrl().then((res) => {
                        resolve(res.data);
                    });
                } catch (error) {
                    reject(error);
                }
            });
        },

        updateUserProfile(evt) {
            evt.preventDefault();
            const body = {
                name: this.form.name,
                phoneNumber: this.form.phone,
                organization: this.form.organization,
            };
            apiService.updateUserProfile(body).then(() => {
                this.$store.state.toastMessage = "User Profile Updated";
            }).catch(() => {
                console.log("error");
                this.$store.state.toastMessage = "Error Updating User Profile";
            });
        },

        uploadFooterDetails(objectFields, file, url) {
            this.$store.state.toastMessage = "";
            return new Promise((resolve, reject) => {
                try {
                    const formData = new FormData();
                    formData.append("key", objectFields["key"]);
                    formData.append(
                        "AWSAccessKeyId",
                        objectFields["AWSAccessKeyId"]
                    );
                    formData.append(
                        "x-amz-security-token",
                        objectFields["x-amz-security-token"]
                    );
                    formData.append("policy", objectFields["policy"]);
                    formData.append("signature", objectFields["signature"]);
                    formData.append("file", file);
                    apiService.uploadFooterDetails(url, formData).then((res) => {
                            this.$store.state.toastMessage = "Created";
                            resolve(res.status);
                        });
                } catch (err) {
                    reject(false);
                }
            });
        },
        onReset(evt) {
            evt.preventDefault();
            // Reset our form values
            this.form.email = "";
            this.form.name = "";
            this.form.phone = null;
            this.form.organization = "";
        },
        changeMethod() {
            console.log(this.selected);
        },
        validate(key) {
            switch (key) {
                case "name":
                    return this.validateName();
                case "email":
                    return this.validateEmail();
                case "phoneNo":
                    return this.validatePhone();
                case "organization":
                    return this.validateOrganization();
            }
        },
        validateName() {
            if (this.form.name) {
                this.formError.name = '';
                return true;
            }
            this.formError.name = "Name Required";
        },
        validateEmail() {
            let regexEmail = /^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/;
            if (!this.form.email) {
                this.formError.email = "Email Required";
                return false;
            }
            if (!regexEmail.test(this.form.email)) {
                this.formError.email = "Please Enter Valid Email";
                return false;
            }
            this.formError.email = "";
            return true;
        },
        validateOrganization() {
            if (!this.form.organization) {
                this.formError.organization = "Organization Name Required";
                return false;
            }
            this.formError.organization = "";
            return true;
        },
        validatePhone() {
            const validationRegex =
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
            if (!this.form.phone) {
                this.formError.phone = "Phone Number Required";
                return false;
            }
            if (!this.form.phone.match(validationRegex)) {
                this.formError.phone = "Enter Valid Phone Number";
                return false;
            }
            this.formError.phone = "";
            return true;
        },
        validateAll() {
            const validationFields = [
                "name",
                "email",
                "phoneNo",
                "organization",
            ];
            let val = null;
            for (let i = 0; i < validationFields.length; i++) {
                const isValid = this.validate(validationFields[i]);
                if (!isValid) {
                    val = false;
                }
            }
            if (val == false) {
                return false;
            }
            return true;
        },
        onEditorReady(editor) {
            console.log(" => editor: ", editor);
            const contentWrapper = editor.ui.getEditableElement();
            contentWrapper.style.minHeight = '270px';
        }
    },
    async mounted() {
        // this.$store.state.isLoading = true;
        console.log(" => ref value: ", this.$refs.newEditor);
        const preContent = await this.getPreAssignedFooterUrl();

        this.form = {
            email: preContent.profileDetails.email,
            name: preContent.profileDetails.name,
            phone: preContent.profileDetails.phone_number,
            organization: preContent.profileDetails.organization,
        };

        apiService.getFooterTemplate(preContent.fileObject).then((res) => {
            this.content = res.data;
        }).finally(() => {
            this.$store.state.isLoading = false;
        })
    },
};
</script>

<style scoped>
.rovlin-prod-user-profile-container .card-body {
    padding: 0px;
}

.rovlin-prod-preview-container {
    width: 100%;
    height: 306px;
    min-height: 100%;
    border: 1px solid rgb(189, 191, 194);
    overflow: auto;
}

.rovlin-prod-preview-title {
    height: 47px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: 600;
}

.rovlin-prod-content-title {
    height: 47px;
    padding: 10px 20px;
    font-size: 22px;
    font-weight: 600;
}

.rovlin-prod-preview-content {
    padding: 0px 27px;
}
</style>
