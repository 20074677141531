<template>
    <div class="container-fluid main-content-container pb-4">
        <div class="row g-0 rovlin-prod-page-header py-4">
            <div class="col col-sm-4 text-center text-sm-start mb-4 mb-sm-0">
                <h3 class="rovlin-prod-page-title">History</h3>
            </div>
        </div>
        <!-- List Templates -->
        <div>
            <vue-good-table mode="remote" v-on:page-change="onPageChange" v-on:per-page-change="onPerPageChange"
                :pagination-options="{
                    enabled: true,
                    perPageDropdownEnabled: false,
                }" :sort-options="{
                    enabled: false,
                }" :totalRows="totalRecords" :columns="columns" :rows="rows">
                <template #table-row="{ row, formattedRow, column }">
                    <span v-if="column.field == 'name'" style="width:300px;display:inline-block" class="text-truncate">
                        {{ row.name }}
                    </span>
                    <span v-if="column.field == 's_date'">
                        {{ formatDate(row.s_date) }}
                    </span>
                    <span v-if="column.field == 'e_date'">
                        {{ formatDate(row.e_date) }}
                    </span>
                    <span v-if="column.field == 'c_status'" style="padding: 20px;cursor: pointer;">
                        <a @click="openStats(row)">
                            <strong class="text-decoration-underline text-muted fw-fs">{{ formattedRow[column.field] + " "
                                }}</strong>
                        </a>
                        <Popover v-if="row.c_status === 'TERMINATED'" icon="bi-info-circle" :content="row.c_status"
                            title="Campaign Status" />
                    </span>
                </template>
            </vue-good-table>
        </div>
        <!-- Modal -->
        <button type="button" class=" btn rounded-4 btn-primary d-none" data-bs-toggle="modal" ref="showModal"
            data-bs-target="#exampleModal">
            Launch static backdrop modal
        </button>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Vacation Testing Stats</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="closeStats()"></button>
                    </div>
                    <div class="modal-body">
                        <CampaignStats v-if="showStats && campaignStatsDetails"
                            :campaignStatsDetails="campaignStatsDetails" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";
import CampaignStats from "../campaign/CampaignStats.vue";
import Popover from "@/components/shared/Popper/Popover.vue"
export default {
    name: "History-vue",
    components: {
        CampaignStats,
        Popover
    },
    campaigns: [],
    data() {
        return {
            isLoading: false,
            showStats: false,
            totalRecords: 0,
            active: 0,
            progress: 0,
            pc: 0,
            comp: 1,
            terminated: 0,
            columns: [
                {
                    label: "S No.",
                    field: "SNo",
                },
                {
                    label: "Name",
                    field: "name",
                },
                {
                    label: "Template",
                    field: "temp_name",
                },
                {
                    label: "Start Date",
                    field: "s_date",
                },
                {
                    label: "End Date",
                    field: "e_date",
                },
                {
                    label: "Status",
                    field: "c_status",
                },
            ],
            rows: [],
            serverParams: {
                page: 1,
                perPage: 10,
            },
        };
    },
    methods: {
        getCampaigns() {
            this.$store.state.isLoading = true;
            APIService.getCampaigns(
                this.serverParams.page,
                this.active,
                this.progress,
                this.pc,
                this.comp,
                this.terminated
            ).then((res) => {
                this.$store.state.isLoading = false;
                this.totalRecords = res.data.camp_count;
                this.$store.state.campaigns = res.data.campaign_details;
                this.rows = APIService.objectItemIndex(
                    this.$store.state.campaigns, this.serverParams.page - 1);

            })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.isLoading = false;
            this.getCampaigns();
        },
        onPageChange(params,id) {
            this.updateParams({ page: params.currentPage });
            this.$router.push({
                name: "history",
                params: { group: this.groupId, groupName: this.groupName, id: id }, query: { page: params.currentPage }
            });

        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
        },
        formatDate(value) {
            return value.split(' ')[0]; // Extract the date part from the full date string
        },

        closeStats() {
            this.showStats = false;
            this.campaignStatsDetails = null;
        },

        openStats(info) {
            console.log("it runs", info)
            this.showStats = true;
            this.campaignStatsDetails = info;
            this.$refs.showModal?.click();
        },
    },
    created() {
        this.getCampaigns();
    },
};
</script>

<style scoped>
:deep(label) {
    display: none !important;
    position: static;
}

:deep(.form-group) {
    padding: 10px 0px !important;
}
::v-deep .vgt-wrap,
::v-deep .vgt-responsive,
::v-deep .vgt-table th {
    position: static !important;
}
.vue-good-table-wrapper {
    margin-top: 20px;
}

:deep(.vgt-table th) {
    text-align: center;
    background-color: #f5f6fa;
    font-weight: bold;
    /* padding: 10px; */
    border-bottom: 2px solid #ddd;
}

:deep(.vgt-table td) {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}
::v-deep .vgt-responsive {
    padding: 0rem 1rem !important;
    background: white !important;
}

::v-deep table.vgt-table {
    border: none;
}

::v-deep .vgt-left-align {
    /* padding: 0.5em .5em !important; */
}

::v-deep .vgt-table.bordered th {
    border: none;
}

::v-deep .vgt-table th {
    border-bottom: 1px solid #ddd !important;
    /* border-top: 1px solid #ddd !important; */
}

::v-deep .vgt-table.bordered td {
    border: none;
}

::v-deep .vgt-table td {
    border-bottom: 1px solid #ddd !important;
}

::v-deep .vgt-wrap__footer {
    border: none !important;
    background: #fff !important;

}

::v-deep .vgt-table thead th {
    background: #fff !important;
}
</style>
