<template>
    <nav class="footer fixed-bottom d-flex align-items-center justify-content-between px-4 py-2">
        <div class="d-flex align-items-center" style="column-gap: 25px;">
            <a href="https://www.rovlin.com/" class="text-white text-decoration-none font-12 foo-link">Home</a>
            <a href="https://www.rovlin.com/features"
                class="text-white text-decoration-none font-12 foo-link">Features</a>
            <a href="https://www.rovlin.com/pricing"
                class="text-white text-decoration-none font-12 foo-link">Pricing</a>
            <router-link to="/feedback" class="text-white text-decoration-none font-12 foo-link">Feedback</router-link>
            <a href="https://www.rovlin.com/contact" class="text-white text-decoration-none font-12 foo-link">Contact
                Us</a>
        </div>
        <div class="d-flex align-items-center" style="column-gap: 25px;">
            <div class="text-white text-decoration-none font-12">Copyright © {{ new Date().getFullYear() }}, Rovlin
            </div>
            <router-link to="/privacy" class="text-white text-decoration-none font-12 foo-link">Privacy</router-link>
            <router-link to="/terms" class="text-white text-decoration-none font-12 foo-link">Terms</router-link>
        </div>

    </nav>
</template>

<script>
export default {
    name: 'FooterNavbar',
}
</script>

<style>
.footer {
    background-color: #0c244a;
}

.font-12 {
    font-size: 12px;
}

.foo-link:hover{
    transform: scale(1.1);
    opacity:  0.7;
}
@media (max-width: 650px) {
    .footer {
        flex-direction: column !important;
    }
    .d-flex .align-items-center{
        column-gap: 17px !important;
    }
}
</style>
